import React, { useState, useCallback } from 'react';
import { jwtDecode } from "jwt-decode"; // Correct the import as jwtDecode is a function, not a named export
import { Link, useNavigate } from 'react-router-dom';
import { BellIconSVG, SearchSVG, Toggle } from '../../Icons/icons'
import CreateCourseModal from '../../Teacher/CreateCourseModal';
import axios from 'axios';
import { BASEURL } from '../../../constants';

const Navbar = ({ setIsCollapsed, searchTerm, setSearchTerm }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    //state related to AI assistant
    const [aiToggle , setAiToggle ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contentNeeds, setContentNeeds] = useState('');
    const [textLength, setTextLength] = useState('');
    const [readingLevel, setReadingLevel] = useState('');
    const [result, setResult] = useState('');
    const [copied, setCopied] = useState(false);

    const token = localStorage.getItem('auth');
    const decoded = jwtDecode(token);
    const schoolName = decoded.organisation_name;
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    
    function handleGenerateContent() {
        setLoading(true);
        //
        axios.post(`${BASEURL}/api/get-content`, {
            prompt: contentNeeds,
            maxTokens: textLength,
            readingLevel: readingLevel
        }).then((response) => {
            console.log('response ', response);
            setLoading(false);
            setCopied(false);
            let result = response.data.content;
            //convert into object
            result = result.replace('"Answer":', '');
            result = result.slice(2, -2);
            setResult(result);
        })
    }

    function handleLogout() {
        console.log('handling logout')
        localStorage.removeItem('auth');
        navigate('/login')
    }
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const searchHandler = (e) => {
        setSearchTerm(e.target.value)
    }


    return (
        <>
            <CreateCourseModal isOpen={isModalOpen} onClose={handleCloseModal} />
            {
                aiToggle && (
                    <div className="fixed inset-0 z-50 flex justify-end">
                        <div className="w-2/6 h-full bg-white shadow-xl p-4 transform transition-transform duration-300 ease-in-out">

                            {/* Add your sidebar content here */}
                            <div className="mt-4">

                                <div className="flex flex-row justify-between">
                                    <h2 className="text-lg font-semibold">AI Content Generator</h2>
                                    <button onClick={() => { setAiToggle(false)}} className="text-gray-700 hover:text-red-500 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>

                                    </button>
                                </div>

                                <div className="mb-2 mt-8">
                                    <label class="text-sm text-gray-700">
                                        Describe your content needs
                                    </label>

                                    <input value={contentNeeds} onChange={(e) => { setContentNeeds(e.target.value) }} class="block mt-2 w-full px-2 py-2 text-xs text-gray-700 bg-white border border-gray-200" type="text" name="text" />
                                </div>

                                <div className="mb-2">
                                    <label class="text-sm text-gray-700">
                                        Number of Words
                                    </label>

                                    <input type='number' min='0' value={textLength} onChange={(e) => { setTextLength(e.target.value) }} name="text"
                                        class=" block mt-2 w-full px-2 py-2 text-sm text-gray-700 bg-white border border-gray-200 " />
                                </div>

                                <div className="mb-2">
                                    <label class="text-sm text-gray-700">
                                        Reading Level (Age)
                                    </label>

                                    <input autoComplete="off" value={readingLevel} onChange={(e) => { setReadingLevel(e.target.value) }} type="text" name="text"
                                        class=" block mt-2 w-full px-2 py-2 text-sm text-gray-700 bg-white border border-gray-200  " />
                                </div>
                                {/* 
                                <div className="mt-4 flex items-center justify-end gap-x-2">

                                    <button className="px-4 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40">
                                        Generate
                                    </button>
                                </div> */}

                                <div className="mt-2 flex items-center justify-end gap-x-2">
                                    <button disabled={loading} onClick={handleGenerateContent} className="px-4 py-2.5 text-sm font-medium text-white bg-customPrimaryPurple  transition-colors duration-300 transform ">
                                        {
                                            loading ? (
                                                <>
                                                    <svg aria-hidden="true" role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                                                    </svg>
                                                    Generating
                                                </>
                                            ) : (
                                                <>Generate</>
                                            )
                                        }
                                    </button>
                                </div>

                                <div className="mt-2">
                                    <label class="text-sm text-gray-700 ">
                                        Result
                                    </label>
                                    <textarea value={result} rows={6} type="text" name="text"
                                        className="text-xs block mt-2 w-full px-2 py-2 text-gray-700 bg-white border border-gray-200  " />
                                    <div className="text-right">
                                        {
                                            copied ? (
                                                <button className="px-4 py-2.5 text-sm font-medium text-white bg-customPrimaryPurple  transition-colors duration-300 transform mt-2">Copied</button>
                                            ) : (
                                                <button onClick={(e) => {
                                                    navigator.clipboard.writeText(result).then(function () {
                                                        setCopied(true);
                                                    })
                                                }} className="px-4 py-2.5 text-sm font-medium text-white bg-customPrimaryPurple  transition-colors duration-300 transform  focus:ring-opacity-40 mt-2">Copy</button>
                                            )
                                        }
                                    </div>
                                </div>


                                {/* Add more sidebar elements as needed */}
                            </div>
                        </div>
                    </div>
                )
            }
            <div onClick={() => {
                setAiToggle(true)
            }} className='fixed w-12 h-12 rounded-full bottom-5 right-5 bg-customPrimaryPurple flex items-center justify-center text-white cursor-pointer'>
                AI
            </div>
            <nav className="flex items-center justify-between h-16 px-6 py-4 bg-white border-b">
                <div className="flex items-center gap-6">
                    <span className='cursor-pointer' onClick={() => {
                        setIsCollapsed((isCollapsed) => {
                            return !isCollapsed
                        })
                    }}>
                        <Toggle />
                    </span>
                    <div>
                        <div className='h-[40px] w-[400px] flex gap-2 items-center px-2 py-1 border border-borderColor'>
                            <span>
                                <SearchSVG />
                            </span>
                            <div className='grow'>
                                <input value={searchTerm} onChange={searchHandler} className='w-full outline-none' type="text" placeholder='Search anything' />
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex flex-col items-center">
            <h2 className="text-xl font-bold text-center text-gray-700">BlissIQ</h2>
        </div>
        <div className="h-6 border-l-2 border-gray-300 mx-4"></div>
        <div className="flex flex-col items-center">
            <span className="text-lg font-medium text-gray-700">{schoolName}</span>
        </div> */}
                </div>

                <div className="relative flex gap-4 items-center">
                    <div onClick={handleOpenModal} className='flex px-3 py-2 bg-customPrimaryPurple text-white cursor-pointer'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M12.3 6V18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.30005 12H18.3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <div>
                            <button>Create course</button>
                        </div>
                    </div>
                    <BellIconSVG />
                    <div className='w-[2px] h-[30px] bg-borderColor'>

                    </div>
                    <img
                        className="object-cover w-8 h-8 rounded-full cursor-pointer"
                        src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=880&h=880&q=100"
                        alt="User avatar"
                        onClick={toggleDropdown}
                    />

                    {isDropdownOpen && (
                        <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white  shadow-xl">
                            <Link to='/profile' className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>

                                <span className="mx-1">View Profile</span>
                            </Link>
                            <button onClick={handleLogout} className="flex w-full items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                                </svg>
                                <span className="mx-1">Log Out</span>
                            </button>
                        </div>
                    )}
                </div>
            </nav>

        </>
    );
};

export default Navbar;
