import { useEffect, useState } from "react";
import axios from 'axios';
import { BASEURL } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { HorizontalThreeDots } from "../../Icons/icons";

const CourseCard = ({ course, courseId, onClickHandler, setShowDeleteModal, type }) => {
    console.log("course card called", course)
    const [coverImage, setCoverImage] = useState("");
    const [courseInfo, setCourseInfo] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const navigate = useNavigate();

    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(true);
            image.onerror = () => reject(false);
            image.src = imageUrl;
        });
    }

    useEffect(() => {

        async function getRequiredData() {
            // const fetchCourseInfo = await axios.get(`${BASEURL}/api/fetch-course-info`, {
            //     params: {
            //         courseId: courseId,
            //     },
            // })

            try {
                const response = await axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: courseId });
                if (response.data.dataUrl) {
                    const imageExists = await checkImageExists(response.data.dataUrl);
                    if (imageExists) {
                        setCoverImage(response.data.dataUrl);
                    } else {
                        console.log(`Image for course ${courseId} does not exist in S3 bucket`);
                        // images[course.id] = null; // or a default image URL
                    }
                } else {
                    console.log(`No image URL received for course ${courseId}`);
                    // images[course.id] = null; // or a default image URL
                }

            } catch (error) {

            }
            // console.log("fetchCourseInfo for course id", courseId , fetchCourseInfo.data.rows)
            // setCourseInfo(fetchCourseInfo.data.rows)

        }

        getRequiredData()
    }, [courseId])

    return (
        <>
            {
                course && (
                    <div className="w-[264px] h-[309px] relative flex flex-col justify-between border bg-white cursor-pointer"
                        onClick={() => {
                            onClickHandler(courseId);
                        }}

                        onMouseEnter={() => { setIsHovering(true) }}
                        onMouseLeave={() => { setIsHovering(false) }}

                    >

                        <div x-data="{ isOpen: true }" className="absolute inline-block right-2 top-2" style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}>
                            {
                                isHovering && (
                                    <button
                                        className="relative z-10 block text-gray-700  border border-transparent rounded-sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsDropdownOpen((isDropdownOpen) => {
                                                return !isDropdownOpen
                                            })
                                        }}>
                                        <HorizontalThreeDots />

                                    </button>
                                )
                            }

                            {isDropdownOpen && (
                                <div onClick={(e) => { e.stopPropagation() }} className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl">
                                    {
                                        type === 'published' && (
                                            <>
                                                <Link to={`/publish-view/${course.master_course_id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                    </svg>
                                                    <span className="mx-1">
                                                        View Course
                                                    </span>
                                                </Link>

                                                <a className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
                                                    </svg>
                                                    <span className="mx-1">
                                                        Course Analytics
                                                    </span>
                                                </a>

                                            </>
                                        )
                                    }
                                    {
                                        type === 'draft' && (
                                            <>
                                                <Link to={`/draft-view/${courseId}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                    </svg>


                                                    <span className="mx-1">
                                                        View Course
                                                    </span>
                                                </Link>



                                                <Link to={`/course-builder/${courseId}`} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                    </svg>


                                                    <span className="mx-1">
                                                        Edit Course
                                                    </span>
                                                </Link>


                                                <span onClick={(e) => { setShowDeleteModal({ show: true, courseId: courseId }) }} className="flex items-center cursor-pointer p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>

                                                    <span className="mx-1">
                                                        Delete Course
                                                    </span>
                                                </span>

                                            </>
                                        )
                                    }
                                </div>

                            )}

                        </div>
                        <div className="h-[160px] text-center bg-gray-200">
                            {
                                coverImage ? (
                                    <img
                                        src={coverImage}
                                        alt={`${coverImage} cover`}
                                        className="object-cover object-center w-full h-full"
                                    />
                                ) : ('No Image available')
                            }
                        </div>
                        <div className="p-4 flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <div className="text-sm text-[#4F4F4F]">{course.first_name ? `${course.first_name} ${course.last_name}` : 'Diacto'}</div>
                                <div className="text-base font-bold h-[48px]">{course.course_name}</div>
                            </div>
                            <div className="flex gap-2 overflow-x-hidden">
                                {
                                    course.tags.map((tag) => {
                                        return <div className="px-2 py-1 border text-xs font-normal">{tag}</div>
                                    })
                                }
                            </div>
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                )
            }
        </>
    );
}

export default CourseCard;