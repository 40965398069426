import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from 'react';
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { BASEURL } from "../../../constants";
import { HomeSVG, MyCoursesSVG, StoreSVG } from "../../Icons/icons";
import { PiStudent } from "react-icons/pi";
import { TbUserStar } from "react-icons/tb";
import Avatar from "../../assets/avatar.jpg";
import { LuSchool } from "react-icons/lu";
import { GoBook } from "react-icons/go";
import { PiHandshake } from "react-icons/pi";
import BlissIqLogo from '../../assets/BlissIQ_logo.png'

const NavLink = ({ to, children, isCollapsed }) => {
    const location = useLocation();
    console.log("location.pathname", location.pathname) //"/myCourses"
    let isActive = location.pathname === to;
// /myCourses/reviewCourses && location.pathname === '/myCourses/reviewCourses'
    if (location.pathname === '/myCourses/draftCourses'  && to === '/myCourses/publishedCourses' ) {
        isActive = true;
    }
    if (location.pathname === '/myCourses/reviewCourses'  && to === '/myCourses/publishedCourses' ) {
        isActive = true;
    }
    if (location.pathname === '/myCourses/rejectedCourses'  && to === '/myCourses/publishedCourses' ) {
        isActive = true;
    }
    return (
        <li style={{ listStyleType: 'none' }}>
            <Link
                to={to}
                className={`flex items-center px-4 py-3 my-1 transition-colors duration-300
                    ${isActive
                        ? 'bg-[#3E3498] text-white'
                        : 'text-gray-600 hover:bg-[#3E3498] hover:text-white'
                    }
                    ${isCollapsed ? '' : 'justify-center'}`}
            >
                {children}
            </Link>
        </li>
    );
};

const NewSidebar = ({ isCollapsed }) => {

    const token = localStorage.getItem('auth');
    const decoded = jwtDecode(token);
    const userRole = decoded.role;
    const userId = decoded.user_id;
    const schoolId = decoded.org_id;


    const location = useLocation();
    const navigate = useNavigate();
    const excludedRoutes = [
        "/login",
        "/verify-otp",
        "/forgotPassword",
        "/resetPassword",
    ];
    const shouldExcludeSidebar = excludedRoutes.includes(location.pathname);


    const handleLogout = () => {
        localStorage.removeItem("auth");
        navigate("/login");
    };

    return (
        <aside className={`z-50 flex flex-col h-full px-4 py-4 overflow-y-auto text-white bg-[#1F1A4C] border-r rtl:border-r-0 rtl:border-l ${isCollapsed ? 'w-[264px]' : 'w-20 items-center overflow-x-hidden'} transition-all duration-300`}>
            <div className="w-full flex justify-center">
                <div className="flex w-[120px] h-[62px] items-center justify-end">
                    <img src={BlissIqLogo} alt="logo" />
                </div>
            </div>
            <div className="flex flex-col justify-between  flex-1 mt-4">
                <nav className="flex-1 space-y-1 ">
                    {userRole === 'creator' && (
                        <>
                            <NavLink to="/home" isCollapsed={isCollapsed}>
                                <HomeSVG />
                                {isCollapsed && <span className="mx-4 text-white font-medium">Home</span>}
                            </NavLink>
                            <NavLink to="/myCourses/publishedCourses" isCollapsed={isCollapsed}>
                                <MyCoursesSVG />
                                {isCollapsed && <span className="mx-4 font-medium text-white">My Courses</span>}
                            </NavLink>
                            <NavLink to='/store' isCollapsed={isCollapsed}>
                                <StoreSVG />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Store</span>}
                            </NavLink>
                        </>
                    )}

                    {userRole === 'student' && (
                        <>
                            <NavLink to="/student/home" isCollapsed={isCollapsed}>
                                <HomeSVG />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Home</span>}
                            </NavLink>
                            <NavLink to="/student/myCourses" isCollapsed={isCollapsed}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                </svg>
                                {isCollapsed && <span className="mx-4 font-medium text-white">My Courses</span>}
                            </NavLink>
                            <NavLink to='/student/course-points' isCollapsed={isCollapsed}>
                                <StoreSVG />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Course Points</span>}
                            </NavLink>
                        </>
                    )}
                    {userRole === 'admin' && (
                        <>
                            <NavLink to={`/admin/home/${userId}/${schoolId}`} isCollapsed={isCollapsed}>
                                <HomeSVG />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Home</span>}
                            </NavLink>
                            <NavLink to={`/admin/allCreators/${schoolId}`} isCollapsed={isCollapsed}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                </svg>
                                {isCollapsed && <span className="mx-4 font-medium text-white">Creators</span>}
                            </NavLink>
                            <NavLink to={`/admin/allStudents/${schoolId}`} isCollapsed={isCollapsed}>
                                <PiStudent className="w-5 h-5" />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Learners</span>}
                            </NavLink>
                            <NavLink to={`/admin/allMentors/${schoolId}`} isCollapsed={isCollapsed}>
                                <TbUserStar className="w-5 h-5" />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Mentors</span>}
                            </NavLink>
                        </>
                    )}
                    {userRole === 'superadmin' && (
                        <>
                            <label className={`px-3 text-xs text-gray-500 uppercase ${isCollapsed ? 'hidden' : 'block'}`}>Dashboard</label>
                            <NavLink to={`/diactoAdmin/home`} isCollapsed={isCollapsed}>
                                <HomeSVG />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Home</span>}
                            </NavLink>

                            <label className={`px-3 text-xs text-gray-500 uppercase ${isCollapsed ? 'hidden' : 'block'}`}>Management</label>
                            <NavLink to={`/diactoAdmin/allSchools`} isCollapsed={isCollapsed}>
                                <LuSchool className="w-5 h-5" strokeWidth={1.5} />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Organizations</span>}
                            </NavLink>
                            {/* <NavLink to={"/diactoAdmin/allMentors"} isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Mentors</span>}
                                    </NavLink>
                                    <NavLink to={"/diactoAdmin/allSponsors"} isCollapsed={isCollapsed}>
                                        <PiHandshake className='w-5 h-5' />
                                        {!isCollapsed && <span className="mx-4 font-medium">Sponsors</span>}
                                    </NavLink> */}

                            <label className={`px-3 text-xs text-gray-500 uppercase ${isCollapsed ? 'hidden' : 'block'}`}>Courses</label>
                            <NavLink to={"/diactoAdmin/courses"} isCollapsed={isCollapsed}>
                                <GoBook className="w-5 h-5" />
                                {isCollapsed && <span className="mx-4 font-medium text-white">My Courses</span>}
                            </NavLink>
                            {/* <NavLink to={"/diactoAdmin/verifyCourses"} isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Verify Courses</span>}
                                    </NavLink> */}
                            <NavLink to='/diactoAdmin/store' isCollapsed={isCollapsed}>
                                <StoreSVG />
                                {isCollapsed && <span className="mx-4 font-medium text-white">Store</span>}
                            </NavLink>
                        </>
                    )}
                </nav>
                <div onClick={handleLogout} className={`flex cursor-pointer items-center gap-4 px-6 -mx-2 mt-6 ${!isCollapsed && 'mt-6'}`}>
                    {/* <img className="object-cover w-8 h-8 rounded-full" src={file || Avatar} alt="User avatar" /> */}

                    <button className="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                        </svg>
                    </button>

                    {isCollapsed && (
                        <>
                            {/* <span className="mx-2 font-medium text-gray-800">{firstName} {lastName}</span> */}
                            <span>
                                Log out
                            </span>
                        </>
                    )}
                </div>
            </div>
        </aside>
    );
}

export default NewSidebar;