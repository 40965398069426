import Navbar from "../Global/Navigation/Navbar";
import NewSidebar from "../Global/Navigation/NewSidebar";
import { Outlet } from "react-router-dom";
import {useState} from 'react';


const Layout = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [searchTerm , setSearchTerm ] = useState("");

    return (
        <div className="flex h-screen w-full">
            <NewSidebar isCollapsed={isCollapsed} />
            <div className="flex flex-col bg-[#F0F1F3] h-full w-[calc(100%-5rem)]">
                <Navbar setIsCollapsed={setIsCollapsed} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                <main className="p-4 bg-gray-50 overflow-auto h-full">
                    <Outlet context={{searchTerm , setSearchTerm }} />
                </main>
            </div>
        </div>
    );
}

export default Layout;