import { useEffect, useState } from "react";
import { CrossSVG } from "../../../Icons/icons";
const Thumbnail = ({ slide, currentSlideId, slideIndex, paginate, setShowModal, setDeleteSlideId }) => {

    const [isHovering, setIsHovering] = useState(false);

    function handleThumbnailClick() {
        //if its not the current slide we need to set and save
        paginate(slide.id)
    }

    function handleSlideDelete(e, slideId) {
        console.log('handle delete clicked');
        e.stopPropagation();
        setShowModal(true);
        setDeleteSlideId(slideId);
    }

    return (<>
        {
            slide ? (
                <div className="shrink-0 flex items-center justify-center relative p-1 w-10 h-10 bg-white" style={slide.id === currentSlideId ? { cursor: 'pointer', overflow: 'hidden', border: '2px solid #382F8A', backgroundColor: '#382F8A', color: 'white' } : { cursor: 'pointer', overflow: 'hidden', border: "1px solid #E0E0E0" }} onClick={handleThumbnailClick}
                    onMouseEnter={() => { setIsHovering(true) }}
                    onMouseLeave={() => { setIsHovering(false) }}
                >
                    {
                        isHovering ? (
                            <span className="absolute top-0 right-0" onClick={(e) => { handleSlideDelete(e, slide.id) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>

                            </span>
                        ) : (null)
                    }
                    <span className="text-sm p-0.5">{slideIndex + 1}</span>
                </div>
            ) : (null)
        }

    </>);
}

export default Thumbnail;






