import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import { BASEURL } from "../../../../../constants";
import { RightChevron, CrossSVG , ImageSVG , VideoSVG } from "../../../../Icons/icons";


const VideoUploadModal = ({setIsDataSaved, setVideoModal, setCurrentSlideData }) => {
    const [videoId, setVideoId] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [videoFile, setVideoFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [toggle, setToggle] = useState('video');

    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        console.log("dropped file ", file);
        if (file && file.type.startsWith('video/')) {
            // const uniqueId = uuidv4();
            setVideoFile(file);
        }
        setIsHovered(false);
    };

    const handleBrowseFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('video/')) {

            setVideoFile(event.target.files[0]);
            // const uniqueId = uuidv4();
            // setUploadState({
            //     renderComponent: 'pc_video',
            //     videoId: uniqueId
            // });
            // Trigger S3 upload here if needed
        }
    };

    const handleUpload = async () => {
        setIsUploading(true);
        const formData = new FormData();
        const uniqueId = uuidv4();
        formData.append('video', videoFile);
        formData.append('key', uniqueId);
        try {
            const response = await axios.post(`${BASEURL}/api/video-upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setIsUploading(false);
            setIsDataSaved(false);
            // setVideoUrl(response.data.videoUrl);
            console.log("setting videoData", uniqueId)
            setVideoModal(false);
            setCurrentSlideData((currentSlideData) => {
                return {
                    ...currentSlideData, content: [...currentSlideData.content,
                    {
                        id: uniqueId,
                        data: {
                            renderComponent: 'pc_video_component',
                            videoKey: uniqueId
                        },
                        type: 'Video',
                        videoIdArray: currentSlideData.videoIdArray.includes(uniqueId) ? [...currentSlideData.videoIdArray] : [...currentSlideData.videoIdArray, uniqueId]
                    }
                    ]
                }
            })
            // setVideoData((videoData) => {
            //     return { ...videoData, videoKey: uniqueId }
            // })
            // setCurrentSlideData((currentSlideData) => {
            //     return { ...currentSlideData, videoIdArray: currentSlideData.videoIdArray.includes(contentId) ? [...currentSlideData.videoIdArray] : [...currentSlideData.videoIdArray, contentId] }
            // })
            // console.log("Response", response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    };
    const getVideoId = (url) => {
        const regExp =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regExp);
        return match ? match[1] : '';
    };

    const handleInputChange = (e) => {
        const inputUrl = e.target.value;
        setVideoUrl(inputUrl)

    };
    const handleYtURLUpload = (e) => {
        if (videoUrl) {
            const id = getVideoId(videoUrl);
            const uniqueId = uuidv4();
            const contentObject = {

                id: uniqueId,
                type: 'Video',
                data: {
                    renderComponent: 'yt_component',
                    ytData: {
                        videoId: id,
                        videoUrl: videoUrl
                    }
                }
            }

            setCurrentSlideData((currentSlideData) => {
                return { ...currentSlideData, content: [...currentSlideData.content, contentObject] }
            })
            setVideoModal(false);
            setIsDataSaved(false);
            //we also need to call auto-save 
        } else {
            //show error to put the url
            console.log("here")
        }
    }

    function clearVideo() {
        if (videoFile) {
            setVideoFile(null)
        }
    }

    function clearYoutubeUrl(){
        if(videoUrl){
            setVideoUrl("");
        }
    }
    return (

        <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <div style={{ minWidth: '40%' }} className="relative p-3 h-auto transform flex-col justify-evenly bg-white text-left shadow-xl transition-all my-8">
                        <>
                            <div className="text-right">
                                <span style={isUploading ? { pointerEvents :'none'}:{}} className="inline-block cursor-pointer" onClick={() => {
                                    setVideoModal(false)
                                }}>
                                    <CrossSVG />
                                </span>
                            </div>
                            <div className="flex space-x-4 mb-4">
                                <label className="flex space-x-2">
                                    <input disabled={isUploading} type="radio" value={`pc_video_component`} name="uploadOption" className="form-radio" checked={toggle === "video"} onChange={() => {
                                        // setVideoData({ renderComponent: 'pc_video_component' })
                                        setToggle('video')
                                    }} />
                                    <span>Upload</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input disabled={isUploading} type="radio" value={`yt_component`} name="uploadOption" className="form-radio" checked={toggle === "youTube"} onChange={() => {
                                        // setVideoData({ renderComponent: 'yt_component' })
                                        setToggle('youTube')
                                    }} />
                                    <span>Embed link</span>
                                </label>
                            </div>

                            {
                                toggle === 'video' && (
                                    <>
                                        <div
                                            className={`border-2 border-dashed p-4 cursor-pointer ${isHovered ? 'border-blue-500 bg-blue-100' : 'border-gray-300'}`}
                                            onDragOver={(e) => {
                                                e.preventDefault();
                                                setIsHovered(true);
                                            }}
                                            onDragLeave={() => setIsHovered(false)}
                                            onDrop={handleFileDrop}
                                        >
                                            {
                                                videoFile ? (
                                                    <div>{videoFile.name}</div>
                                                ) : (
                                                    <>
                                                        <div className="text-center">
                                                            <div className="w-20 h-12 mx-auto mb-2 bg-[#EDECF9] rounded-xl flex items-center justify-center">
                                                                {/* <svg
                                                                    className="w-6 h-6 text-gray-500"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    viewBox="0 0 24 24"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7l9 6 9-6M5 19h14V8l-7 4.5L5 8v11z" />
                                                                </svg> */}
                                                                <VideoSVG />
                                                            </div>
                                                            <p className="text-gray-600">Upload or drag & drop file here</p>
                                                            <button
                                                                onClick={() => document.getElementById('fileInput').click()}
                                                                className="text-blue-500 font-semibold"
                                                            >
                                                                Browse
                                                            </button>
                                                        </div>
                                                        <input
                                                            id="fileInput"
                                                            type="file"
                                                            accept="video/*"
                                                            className="hidden"
                                                            onChange={handleBrowseFileChange}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                        <p className="text-sm text-gray-500 mt-2">The maximum size per file is 5 MB</p>
                                        <div className="flex justify-end mt-4 space-x-2">
                                            <button disabled={isUploading} onClick={clearVideo} className="px-2 py-1 bg-gray-200 text-gray-700">Clear</button>
                                            <button onClick={handleUpload} className="px-2 py-1 bg-customPrimaryPurple text-white">{ isUploading ? 'Uploading...':'upload'}</button>
                                        </div>

                                    </>
                                )
                            }
                            {
                                toggle === 'youTube' && (
                                    <div className='w-full p-5'>
                                        {
                                            !videoId && (
                                                <label className='gap-1'>
                                                    <input
                                                        value={videoUrl}
                                                        className='text-sm border border-borderColor w-full p-1 px-2 mb-4'
                                                        type="text"
                                                        onChange={handleInputChange}
                                                        placeholder="Enter your url https://www.youtube.com/watch?v=VIDEO_ID"
                                                    />
                                                    <div className="flex justify-end mt-4 space-x-2">
                                                        <button disabled={isUploading} onClick={clearYoutubeUrl} className="px-2 py-1 bg-gray-200 text-gray-700">Clear</button>
                                                    <button className='px-2 py-1 bg-customPrimaryPurple text-white' onClick={handleYtURLUpload}>{ isUploading ? 'Uploading...':'upload'}</button>
                                                        
                                                    </div>
                                                </label>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </>

                    </div>
                </div>
            </div>
        </div>


    );
}

export default VideoUploadModal;