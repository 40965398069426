import { useEffect, useState } from "react";
import { BASEURL } from '../../../../constants';
import axios from "axios";
import { Resizable } from "re-resizable";
import TestThumbnail from "./TestThumbnail";
import { useParams } from "react-router-dom";
import { PlusSVG, WatchSVG } from "../../../Icons/icons";

const Test = ({ validateQuestion, selectedTestId, question, setQuestion, questionsArray, setQuestionsArray, setIsDataSaved, setImagesArray, setCurrentQuestionId, currentQuestionId,
    numberOfQuestions, testTimeMinutes, setNumberOfQuestions, setTestTimeMinutes, imagesArray, autoSaveQuestion, selectedTopicId
}) => {

    const [showModal, setShowModal] = useState(false);
    const [deleteQuestionId, setDeleteQuestionId] = useState(null);

    const { courseId } = useParams();
    //how are we handling the loading and the error states 
    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }
    const newSample = {
        "id": 2,
        "test_id": 25,
        "text": "",
        "time": null,
        "answer_type": "single",
        "options": [],
        "correct_answer": [],
        "image_data": {
            "image": "",
            "width": "",
            "height": ""
        },
        "image_key": "",
        "sequence": 1
    }

    function addOption() {
        /*
            just push an empty string 
        */
        setQuestion({ ...question, options: [...question.options, ''] })
    }

    function handleResize(e, d, ref, delta) {
        console.log(e, d, ref, delta);
        setQuestion((question) => {
            const newQuestion = { ...question, image_data: { ...question.image_data, width: ref.style.width, height: ref.style.height } };
            return newQuestion;
        });
        setIsDataSaved(false);
    }

    function handleImageUpload(event) {
        //update the img in image_data : {width , height , image : url.createObjectURL }
        //also we need to update imagesArray 
        // we need to empty the imagesArray when we change between slide-group and test.
        const image = event.target.files[0];
        const url = URL.createObjectURL(image);
        //console.log("asdfsadfsdf" , url);
        event.target.value = null;
        const imageId = `question${question.id}`
        setImagesArray((imagesArray) => {
            let newImagesArray = [...imagesArray];
            newImagesArray.push({ id: imageId, data: image })
            return newImagesArray;
        })
        setQuestion({ ...question, image_data: { ...question.image_data, image: url }, image_key: imageId });
        setIsDataSaved(false);
    }

    function deleteOption(optionIndex) {
        /*
            we get the index 
            we just need to delete that [ 1 , 2 , 3 , 4 ]
        */
        const newOptionsArray = question.options.filter((option, index) => optionIndex !== index);
        setQuestion({ ...question, options: newOptionsArray })
    }

    function saveOption(e, optionIndex) {
        //whenever user write text for an answer
        const newOptions = [...question.options];
        newOptions[optionIndex] = e.target.value;
        setQuestion({ ...question, options: newOptions })
    }

    function saveCorrectAnswer(e, optionIndex) {
        //whenver user marks correct anser for the question
        //put that option into the array
        if (question.answer_type === 'single') {
            //replace 
            if (e.target.checked) {
                setQuestion({ ...question, correct_answer: [e.target.value] })
            } else {

                setQuestion({ ...question, correct_answer: [] })
            }
        }
        if (question.answer_type === 'multiple') {
            if (e.target.checked) {
                console.log('inside checked', question, [...question.correct_answer, e.target.value], e.target.value);

                setQuestion({ ...question, correct_answer: [...question.correct_answer, e.target.value] })
            } else {
                console.log('inside unchecked');
                console.log(question, question.correct_answer, e.target.value);
                const newCorrectAnswerArray = question.correct_answer.filter(ans => ans !== e.target.value);
                setQuestion({ ...question, correct_answer: newCorrectAnswerArray })
            }
        }
    }
    function updateQuestion(e) {
        setQuestion({ ...question, text: e.target.value });
        setIsDataSaved(false);
    }

    function handleAnswerTypeChange(e) {
        // single , multiple , audio 
        /* 
            what did he switch to 
            switched to mcq 
            convert the input to radio 
            reset the correct answers will have to mark again 
            how are we going to make the inputs controlled 
            checked attribute takes true or false 
            correct_ansewer contains [{ checked : 'true' , value: 'answer'}] 
            single -- reset the correct_answer array and let user click on the correct 
        */
        setQuestion({ ...question, answer_type: e.target.value, correct_answer: [] });
        setIsDataSaved(false);
    }






    function addQuestion(sequence) {

        if (!validateQuestion()) {
            // If validation fails, return early
            return;
        }

        //make api call to add question 
        //add validation if the time is there and if the text is added and if the correct answer is marked then add the next question its better than no validation 
        autoSaveQuestion().then((response) => {
            if (response.data.status === 'successfully saved') {
                axios.post(`${BASEURL}/api/add-question`, {
                    testId: selectedTestId,
                    sequence: sequence,
                    topicId: selectedTopicId,
                    courseId: courseId
                }).then((addQuestionResponse) => {
                    //we will get the question object itself
                    setQuestionsArray((questionArray) => {
                        let newQuestionArray = [...questionArray];
                        //find the slide index and update the content
                        const questionSlideIndex = newQuestionArray.findIndex(q => q.id === question.id);
                        newQuestionArray[questionSlideIndex] = question;
                        newQuestionArray.push(addQuestionResponse.data);
                        return newQuestionArray;
                    });
                    setQuestion(addQuestionResponse.data);
                    setCurrentQuestionId(addQuestionResponse.data.id);
                    //forgot to empty the images array when you auto-saved.
                    setImagesArray([]);
                })
            }
        })
    }


    function handleDeleteImage() {
        if (!imagesArray.some(obj => obj.id === `question${question.id}`)) {
            axios.delete(`${BASEURL}/api/delete-image`, {
                data: {
                    key: `question${question.id}`
                }
            }).then((response) => {
                if (response.data.message === 'deleted successfully') {
                    //we dont need to set questions array over here because when auto-save is triggred these changes will be stored in questionsArray.
                    setQuestion({ ...question, image_data: { ...question.image_data, image: '' }, image_key: '' })
                    setIsDataSaved(false);
                }
            })
        } else {
            console.log("here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
            setImagesArray((imagesArray) => {
                let newImagesArray = imagesArray.filter(obj => obj.id === `question${question.id}`);
                return newImagesArray;
            })
            setQuestion({ ...question, image_data: { ...question.image_data, image: '' }, image_key: '' })
        }
    }

    function deleteQuestion() {
        if (currentQuestionId === deleteQuestionId) {
            if (questionsArray.length === 1) {
                //delete then add a new question
                axios.delete(`${BASEURL}/api/delete-question`, {
                    params: {
                        questionId: deleteQuestionId
                    }
                }).then((response) => {
                    if (response.data.status === "successfully deleted") {
                        axios.post(`${BASEURL}/api/add-question`, {
                            testId: selectedTestId,
                            sequence: 1,
                            topicId: selectedTopicId,
                            courseId: courseId
                        }).then((addQuestionResponse) => {
                            const newQuestion = addQuestionResponse.data;
                            setQuestionsArray([newQuestion]);
                            setQuestion(newQuestion);
                            setCurrentQuestionId(newQuestion.id);
                            setShowModal(false);
                            setDeleteQuestionId(null);
                        })
                    }
                })
            } else {
                if (questionsArray[questionsArray.length - 1].id === deleteQuestionId) {
                    axios.delete(`${BASEURL}/api/delete-question`, {
                        params: {
                            questionId: deleteQuestionId
                        }
                    }).then((response) => {
                        if (response.data.status === "successfully deleted") {
                            const prevousQuestionIndex = questionsArray.findIndex(question => question.id === deleteQuestionId) - 1;
                            setQuestion(questionsArray[prevousQuestionIndex]);
                            setCurrentQuestionId(questionsArray[prevousQuestionIndex].id);
                            setQuestionsArray((questionsArray) => {
                                const newQuestionsArray = questionsArray.filter(question => question.id !== deleteQuestionId);
                                return newQuestionsArray;
                            });
                            setShowModal(false);
                            setDeleteQuestionId(null);
                        }
                    })
                } else {
                    //move forward
                    axios.delete(`${BASEURL}/api/delete-question`, {
                        params: {
                            questionId: deleteQuestionId
                        }
                    }).then((response) => {
                        if (response.data.status === "successfully deleted") {
                            const nextQuestionIndex = questionsArray.findIndex(question => question.id === deleteQuestionId) + 1;
                            setQuestion(questionsArray[nextQuestionIndex]);
                            setCurrentQuestionId(questionsArray[nextQuestionIndex].id);
                            setQuestionsArray((questionsArray) => {
                                const newQuestionsArray = questionsArray.filter(question => question.id !== deleteQuestionId);
                                return newQuestionsArray;
                            });
                            setShowModal(false);
                            setDeleteQuestionId(null);
                        }
                    })
                }
            }
        } else {
            axios.delete(`${BASEURL}/api/delete-question`, {
                params: {
                    questionId: deleteQuestionId
                }
            }).then((response) => {
                if (response.data.status === 'deleted successfully') {
                    setQuestionsArray((questionsArray) => {
                        const newQuestionsArray = questionsArray.filter(question => question.id !== deleteQuestionId);
                        return newQuestionsArray;
                    })
                    setShowModal(false);
                    setDeleteQuestionId(null);
                }
            })
        }
    }

    return (<>
        {
            showModal &&
            <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                        <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                            <div className='flex w-full justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                </svg>
                            </div>
                            <div className="flex justify-center py-2 px-3">
                                <span className='font-bold'>Are you sure?</span>

                            </div>
                            <div className="flex justify-center py-2 px-3">
                                <span className='text-xs text-gray-600'>Do you really want to delete this?This action cannot be undone.</span>

                            </div>
                            <div className="flex justify-around">
                                <button className='px-3 py-1 border-2 border-gray-300 rounded-lg text-gray-500 text-xs' onClick={() => { setShowModal(false) }}>Cancel</button>
                                <button onClick={() => { deleteQuestion() }} className='px-3 py-1 bg-slate-500 text-white rounded-lg text-xs'>Yes, Delete it!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            questionsArray ? (
                <section className="flex flex-col w-full items-center h-full overflow-auto" style={{ width: '100%' }}>
                    <div className="flex w-full p-2 px-6 items-center justify-between border-b">
                        <div className="flex gap-x-3 w-full overflow-x-scroll pt-2 pb-1">
                            {
                                questionsArray ? (
                                    questionsArray.map((q, index) => {
                                        return <TestThumbnail validateQuestion={validateQuestion} key={index} autoSaveQuestion={autoSaveQuestion} setShowModal={setShowModal} setDeleteQuestionId={setDeleteQuestionId} setCurrentQuestionId={setCurrentQuestionId} q={q} question={question} currentQuestionId={currentQuestionId} index={index} setQuestionsArray={setQuestionsArray} questionsArray={questionsArray} setQuestion={setQuestion} />
                                    })
                                ) : (null)
                            }
                            <div onClick={() => { addQuestion(questionsArray[questionsArray.length - 1].sequence + 1) }} className='shrink-0 flex justify-center bg-white items-center ml-2 w-10 h-10' style={{ cursor: 'pointer', overflow: 'hidden', border: '1px solid #E0E0E0', backgroundColor: 'rgba(224, 224, 224, 0.30)' }}>
                                <PlusSVG />
                            </div>
                        </div>
                        <div className="flex items-center justify-center gap-2 shrink-0">
                            {/* <label className="text-xs">No. of questions to display during test</label> <input value={numberOfQuestions} onChange={(e) => setNumberOfQuestions(e.target.value)} className="p-1 text-xs border-2 border-solid border-gray-400 rounded-lg w-12" type="number"></input> */}
                            <label className="text-sm">Time for the test:</label>
                            <div className="flex gap-2 items-center border border-[#E0E0E0] p-1">
                                <WatchSVG />
                                <input value={testTimeMinutes} onChange={(e) => { setTestTimeMinutes(e.target.value) }} className="p-1 text-xs w-12 outline-none" type="number" min={0}></input>
                            </div>
                        </div>
                    </div>

                    <div className="h-full w-full flex justify-center py-0 relative overflow-auto">
                        {
                            question ? (
                                <div className="w-full max-w-[800px] p-4 mt-4">
                                    <textarea rows={4} className="w-full mb-1 border p-1 text-xs outline-none" placeholder={question.answer_type === 'audio' ? "Type a sentence you want student to read" : "Type question here"} value={question.text} onChange={(e) => { updateQuestion(e) }}></textarea>
                                    <div className="flex justify-between items-center mb-2">
                                        <div className={`${question.image_data.image ? 'invisible' : 'visible'}`}>
                                            <label className="text-gray-500 text-xs" style={{ color: '#6b7280' }} htmlFor={`mcq-image${question.id}`}><i style={{ cursor: 'pointer' }} className="fa-regular fa-image"></i>   {`Add Image (Optional)`}</label>
                                            <input type='file' accept='image/*' id={`mcq-image${question.id}`} onChange={(event) => handleImageUpload(event)} style={{ display: "none" }}></input>
                                        </div>
                                        <select className="text-xs" onChange={handleAnswerTypeChange} value={question.answer_type}>
                                            <option value={"single"}>Single correct</option>
                                            <option value={"multiple"}>Multiple correct</option>
                                            {/* <option value={"audio"}>Audio</option> */}
                                        </select>
                                    </div>
                                    {
                                        question.image_data.image ? (
                                            <div className="w-4/5 flex gap-2 justify-between">
                                                <Resizable
                                                    className="border mb-2"
                                                    size={{
                                                        width: question.image_data.width,
                                                        height: question.image_data.height
                                                    }}
                                                    maxWidth='100%'
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    lockAspectRatio={false}
                                                    onResizeStop={(e, d, ref, delta) => handleResize(e, d, ref, delta)}
                                                >
                                                    <img src={question.image_data.image} style={{ height: "100%", width: "100%" }}></img>
                                                </Resizable>
                                                <i style={{ cursor: 'pointer' }} onClick={handleDeleteImage} className="fa-regular fa-circle-xmark"></i>
                                            </div>
                                        ) : (null)
                                    }
                                    {
                                        question.answer_type !== 'audio' ? (
                                            <>
                                                <section
                                                    className="text-xs flex flex-col gap-2"
                                                // style={{
                                                //     display: 'grid',
                                                //     gridTemplateColumns: 'auto auto',
                                                //     gridTemplateRows: `repeat(${Math.ceil((question.options.length) / 2)}, 80px)`,
                                                //     gap: '1rem'
                                                // }}
                                                >
                                                    {
                                                        question.answer_type === 'single' ? (
                                                            question.options.map((option, optionIndex) => {
                                                                return (
                                                                    <div className="flex gap-1 h-[40px] border border-solid border-gray-300 items-center px-2 py-0" key={optionIndex} >
                                                                        <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-[16px]" >{`${String.fromCharCode(65 + optionIndex)}`}</span>
                                                                        <input className="text-xl" type="radio" disabled={option ? false : true} value={option} checked={question.correct_answer.includes(option)} name="radio" onChange={(e) => { saveCorrectAnswer(e, optionIndex) }} />
                                                                        <input className="w-4/5 grow outline-none text-xs" type="text" placeholder={`Answer ${optionIndex + 1}`} value={option} onChange={(e) => { saveOption(e, optionIndex) }} />
                                                                        {/* <i onClick={() => { deleteOption(optionIndex) }} style={{ cursor: "pointer" }} className="fa-regular fa-circle-xmark"></i> */}
                                                                    </div>
                                                                )
                                                            })
                                                        ) : (
                                                            question.answer_type === 'multiple' ? (
                                                                question.options.map((option, optionIndex) => {

                                                                    return (
                                                                        <div className="flex gap-1 border border-solid border-gray-300 items-center px-2 py-0 rounded-lg" key={optionIndex} >
                                                                            <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl text-gray-500">{`${String.fromCharCode(65 + optionIndex)}`}</span>
                                                                            <input className="text-xl" type="checkbox" value={option} checked={question.correct_answer.includes(option)} disabled={!option} name="radio" onChange={(e) => { saveCorrectAnswer(e, optionIndex) }} />
                                                                            <input className="w-4/5 outline-none text-xs" type="text" placeholder={`Answer ${optionIndex + 1}`} value={option} onChange={(e) => { saveOption(e, optionIndex) }} />
                                                                            {/* <i onClick={() => { deleteOption(optionIndex) }} style={{ cursor: "pointer" }} className="fa-regular fa-circle-xmark"></i> */}
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            ) : (
                                                                null
                                                            )
                                                        )
                                                    }
                                                </section>
                                                <div className="flex justify-center">
                                                    <button className="mt-2 border px-1 py-2 rounded-md flex items-center justify-center text-gray-400 text-xs bg-gray-100" onClick={addOption}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                        </svg>
                                                        Add option</button>
                                                </div>
                                            </>
                                        ) : (null)
                                    }
                                </div>
                            ) : (null)
                        }


                    </div>
                </section>
            ) : (null)
        }
    </>);
}

export default Test;