import { useEffect, useRef, useState } from 'react';
import './SideBar.css';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASEURL } from '../../../../constants';
import { LeftArrow, DoubleRightArrows, PlusSVG, ImageSVG, PageGroupIcon, TestIcon } from '../../../Icons/icons';
import { checkImageExists } from '../../../../Utils/utilFunctions';

const SideBar = ({ sidebarToggle, validateQuestion, sidebarData, setSidebarData,
    handleSaveCourse,
    setSelectedChapterId, setSelectedTestId,
    selectedChapterId, selectedTestId, autoSaveSlide, autoSaveQuestion,
    setImagesArray, setSelectedTopicId, selectedTopicId, handleAllCoursesClick, baseRoute
}) => {
    console.log("rendeing sidebar ",)
    //related to AI
    const [aiToggle , setAiToggle ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contentNeeds, setContentNeeds] = useState('');
    const [textLength, setTextLength] = useState('');
    const [readingLevel, setReadingLevel] = useState('');
    const [result, setResult] = useState('');
    const [copied, setCopied] = useState(false);

    const [coverImage, setCoverImage] = useState("");
    const [isEditing, setIsEditing] = useState(null);
    const { courseId } = useParams();
    const [isHovering, setIsHovering] = useState("");
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState({
        show: '',
        deleteWhat: 'topic / subTopic / test',
        topicId: '',
        subTopicId: '',
        testId: ''
    });
    //state variable to store the users input then on click of update button we change the semesters state
    //same state variable will be used to update sem name , chap name , section name.
    const [newName, setNewName] = useState("");
    const buttonRef = useRef();
    //const {semesterId , chapterId , sectionId } = useparams();
    const lastActive = useRef(new Date());
    const timeOutDuration = 1000 * 60 * 60;  // 1hour in milliseconds

    function handleGenerateContent() {
        setLoading(true);
        //
        axios.post(`${BASEURL}/api/get-content`, {
            prompt: contentNeeds,
            maxTokens: textLength,
            readingLevel: readingLevel
        }).then((response) => {
            console.log('response ', response);
            setLoading(false);
            setCopied(false);
            let result = response.data.content;
            //convert into object
            result = result.replace('"Answer":', '');
            result = result.slice(2, -2);
            setResult(result);
        })
    }


    function handleToggleClick(event) {
        const accordionHeader = event.currentTarget.parentNode.parentNode;

        accordionHeader.classList.toggle('active');

        const accordionBody = accordionHeader.nextElementSibling;
        if (accordionHeader.classList.contains("active")) {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
        } else {
            accordionBody.style.maxHeight = 0;
        }
    }

    async function fetchImage() {
        const imageURL = await axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: courseId });
        return imageURL;
    }

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const sidebar = document.querySelector('#sidebar');

            // Select all elements with the class 'visible' inside the sidebar
            const visibleElements = sidebar ? sidebar.querySelectorAll('.addTopicItem') : [];

            visibleElements.forEach(element => {
                element.classList.add('invisible');  // Add the 'invisible' class
            });
        })
    }, [])


    useEffect(() => {
        const fetchImage = async () => {
            try {
                console.log("inside fetch image function ", courseId);

                const response = await axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: courseId });
                const imageURL = response.data.dataUrl;


                const imageExists = await checkImageExists(imageURL);

                if (imageExists) {

                    setCoverImage(imageURL);
                } else {
                    // Default behavior when image does not exist
                }
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        };

        fetchImage(); // Call the async function
    }, []); // Add courseId as a dependency if it can change

    useEffect(() => {
        const toggles = document.querySelectorAll('.toggle');

        if (toggles.length > 0) {
            const firstToggle = toggles[0];


            const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            firstToggle.dispatchEvent(event);
        }
    }, []);

    useEffect(() => {
        //this useEffect will run to update the height of the dropdown when user adds a slidegroup or a test

        const accordionHeaders = document.querySelectorAll('div.accordion-header.active');

        accordionHeaders.forEach((accordionHeader) => {
            if (accordionHeader) {
                const accordionBody = accordionHeader.nextElementSibling;
                if (accordionHeader.classList.contains("active")) {
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
                } else {
                    accordionBody.style.maxHeight = 0;
                }
            }
        })

    }, [selectedChapterId, selectedTestId, selectedTopicId])

    useEffect(() => {
        document.addEventListener('keydown', handleUserActivity);
        document.addEventListener('mousemove', handleUserActivity);

        return () => {
            document.removeEventListener('keydown', handleUserActivity);
            document.removeEventListener('mousemove', handleUserActivity);
        }
    }, [])

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            //user inActive for an hour log him out i.e delete the token and route the user to login page 
            //also save his data 
            handleSaveCourse('logout');
        }, timeOutDuration - (new Date() - lastActive.current));

        return () => { clearTimeout(timeOutId) }
    }, [lastActive.current])

    function handleUserActivity() {
        lastActive.current = new Date();
    }

    function addTopic() {

        setError(false)
        axios.post(`${BASEURL}/api/add-topic`, {
            courseId: courseId
        }).then((response) => {
            if (response.data.status === 'success') {
                setSidebarData((sidebarData) => {
                    let newSidebarData = sidebarData;
                    newSidebarData.topics.push({
                        id: response.data.insertId, name: 'Untitled',
                        content: []
                    })
                    return newSidebarData;
                });
                setIsEditing(`topic${response.data.insertId}`)
                setNewName('Untitled');
                // buttonRef.current.click();
            } else {
                setError('adding semester failed try again');
                setNewName('Untitled');
            }
        })
    };

    function addSlideGroup(topicId, type, sequence) {

        function addSubtopic() {
            axios.post(`${BASEURL}/api/add-sub-topic`, {
                courseId: courseId,
                topicId: topicId,
                type: type,
                sequence: sequence
            }).then((response) => {
                if (response.data.status === 'success') {
                    //create a new slide to add into the slide group
                    axios.post(`${BASEURL}/api/add-slide`, {
                        subTopicId: response.data.insertId,
                        sequence: 1,
                        topicId: topicId,
                        courseId: courseId
                    }).then((addSlideResponse) => {
                        // it returns the slide 
                        console.log('add slide response ', addSlideResponse);
                        const createdSlide = addSlideResponse.data;
                        setSidebarData((sidebarData) => {
                            const newTopics = sidebarData.topics.map((topic) => {
                                if (topic.id === topicId) {
                                    return {
                                        ...topic, content: [...topic.content, {
                                            id: response.data.insertId,
                                            name: 'Untitled',
                                            type: type,
                                            sequence: sequence
                                        }]
                                    }
                                } else {
                                    return topic;
                                }
                            })
                            return { topics: newTopics }
                        });
                        // setMainCourseData(res.data);
                        setSelectedChapterId(response.data.insertId);
                        setSelectedTestId(null);
                        setNewName('Untitled');
                        setIsEditing(`subtopic${response.data.insertId}`);
                        setSelectedTopicId(topicId);
                    })

                } else {
                    setError('adding chapter failed try again');
                    setShowModal((showModal) => { return { ...showModal, show: false } })
                }
            })
        }

        if (selectedChapterId) {
            autoSaveSlide().then(() => {
                addSubtopic()
            })
        }
        if (selectedTestId) {
            if (!validateQuestion()) {
                // If validation fails, return early
                return;
            }
            autoSaveQuestion().then(() => {
                addSubtopic()
            })
        }
        if (!selectedChapterId && !selectedTestId) {
            addSubtopic();
        }


    }

    function addTest(topicId, type, sequence) {
        console.log('clicked')
        function addTestAndQuestion() {
            axios.post(`${BASEURL}/api/add-test`, {
                topicId: topicId,
                type: type,
                sequence: sequence,
                courseId: courseId
            }).then((response) => {
                if (response.data.status === 'success') {
                    axios.post(`${BASEURL}/api/add-question`, {
                        testId: response.data.insertId,
                        sequence: 1,
                        topicId: topicId,
                        courseId: courseId
                    }).then((addQuestionResponse) => {
                        //question added 
                        setSidebarData((sidebarData) => {
                            const newTopics = sidebarData.topics.map((topic) => {
                                if (topic.id === topicId) {
                                    return {
                                        ...topic, content: [...topic.content, {
                                            id: response.data.insertId,
                                            name: 'Untitled',
                                            type: type,
                                            sequence: sequence
                                        }]
                                    }
                                } else {
                                    return topic;
                                }
                            })
                            return { topics: newTopics }
                        });
                        setSelectedTestId(response.data.insertId);
                        setSelectedChapterId(null);
                        setNewName('Untitled');
                        setIsEditing(`test${response.data.insertId}`)
                        setSelectedTopicId(topicId)
                        setError(false)
                    })
                }
            })
        }

        if (selectedChapterId) {
            console.log('clicked 1 ')
            autoSaveSlide().then(() => {
                addTestAndQuestion()
            })
        }
        if (selectedTestId) {
            console.log('clicked 2')
            if (!validateQuestion()) {
                // If validation fails, return early
                return;
            }
            autoSaveQuestion().then(() => {
                addTestAndQuestion()
            })
        }
        if (!selectedChapterId && !selectedTestId) {
            console.log('clicked 3')
            addTestAndQuestion();
        }
    }

    function editTopicName(topicId) {
        if (!newName) {
            setError(true);
            return;
        }
        console.log('running to change the name')
        axios.put(`${BASEURL}/api/edit-topic-name`, {
            topicId: topicId,
            name: newName
        }).then((response) => {
            if (response.data.status === 'success') {
                setSidebarData((sidebarData) => {
                    const newTopics = sidebarData.topics.map((topic) => {
                        if (topic.id === topicId) {
                            return {
                                ...topic, name: newName
                            }
                        } else {
                            return topic;
                        }
                    })
                    return {
                        topics: newTopics
                    }
                })
                setIsEditing('');
                setError(false);
                setNewName("");

            } else {
                //handle the failed name update query
            }

            setShowModal((showModal) => { return { ...showModal, show: false } });
        })
    }

    function editSubTopicName(topicId, subTopicId) {
        if (!newName) {
            setError(true);
            return;
        }
        axios.put(`${BASEURL}/api/edit-sub-topic-name`, {
            subTopicId: subTopicId,
            name: newName
        }).then((response) => {
            if (response.data.status === 'success') {
                setSidebarData((sidebarData) => {
                    const newTopics = sidebarData.topics.map((topic) => {
                        if (topic.id === topicId) {
                            return {
                                ...topic, content: topic.content.map((subTopic) => {
                                    if (subTopic.id === subTopicId) {
                                        return {
                                            ...subTopic, name: newName
                                        }
                                    } else {
                                        return subTopic;
                                    }
                                })
                            }
                        } else {
                            return topic;
                        }
                    })
                    return { topics: newTopics };
                })
            }
            // setShowModal((showModal) => { return { ...showModal, show: false } });
            setNewName("");
            setIsEditing(``);
            setError(false);
        })

    }

    function editTestName(semId, testId) {
        if (!newName) {
            setError(true);
            return;
        }

        axios.put(`${BASEURL}/api/edit-test-name`, {
            testId: testId,
            name: newName
        }).then((response) => {
            if (response.data.status === 'success') {
                setSidebarData({
                    topics: sidebarData.topics.map((topic) => {
                        if (topic.id === semId) {
                            return {
                                ...topic, content: topic.content.map((q) => {
                                    if (q.id === testId) {
                                        return {
                                            ...q, name: newName
                                        }
                                    } else {
                                        return q;
                                    }
                                })
                            }
                        } else {
                            return topic;
                        }
                    })
                })
            }
            setIsEditing(``);
            setNewName("");
            setError(false);
        })
    }

    function handleSubTopicClick(chapId, topicId) {
        //if coming from test 

        if (selectedTestId) {

            if (!validateQuestion()) {
                // If validation fails, return early
                return;
            }
            autoSaveQuestion().then((response) => {
                setSelectedChapterId(chapId);
                setSelectedTopicId(topicId);
                setSelectedTestId(null);
                setImagesArray([])
            })
        }
        if (selectedChapterId) {
            autoSaveSlide().then((response) => {
                setSelectedChapterId(chapId);
                setSelectedTopicId(topicId);
                setSelectedTestId(null);
                setImagesArray([])
            })
        }
        if (!selectedChapterId && !selectedTestId) {
            setSelectedChapterId(chapId);
            setSelectedTestId(null);
            setSelectedTopicId(topicId);
        }
    }

    function handleTestClick(testId, topicId) {

        if (selectedTestId) {
            autoSaveQuestion().then((response) => {
                setSelectedTestId(testId);
                setSelectedChapterId(null);
                setSelectedTopicId(topicId);
                setImagesArray([])
            })
        }
        if (selectedChapterId) {
            autoSaveSlide().then((response) => {
                setSelectedTestId(testId);
                setSelectedChapterId(null);
                setSelectedTopicId(topicId);
                setImagesArray([])
            })
        }
        if (!selectedChapterId && !selectedTestId) {
            setSelectedTestId(testId);
            setSelectedChapterId(null);
            setSelectedTopicId(topicId);
        }


    }

    function deleteEntity() {
        // depending on show modal state { show:ture , deleteWhat : 'topic / subTopic / test ' , deleteId : ''}
        if (showModal.deleteWhat === 'subTopic') {
            console.log('show modal', showModal.subTopicId)
            axios.delete(`${BASEURL}/api/delete-sub-topic`, {
                params: {
                    subTopicId: showModal.subTopicId
                }
            }).then((response) => {
                if (response.data.message === 'Slides deleted successfully') {
                    if (selectedChapterId === showModal.subTopicId) {
                        setSelectedChapterId(null);
                    }
                    setSidebarData((sidebarData) => {
                        const newSidebarData = {
                            topics: sidebarData.topics.map((topic) => {
                                if (topic.id === showModal.topicId) {
                                    //we know that 
                                    return {
                                        ...topic, content: topic.content.filter(contentObj => contentObj.id !== showModal.subTopicId)
                                    }
                                } else {
                                    return topic;
                                }
                            })
                        }
                        return newSidebarData;
                    })
                    setShowModal({ ...showModal, show: false })
                }
            })
        }
        if (showModal.deleteWhat === 'test') {
            axios.delete(`${BASEURL}/api/delete-test`, {
                params: {
                    testId: showModal.testId
                }
            }).then((response) => {
                if (response.data.message === 'questions deleted successfully') {
                    if (selectedTestId === showModal.testId) {
                        setSelectedTestId(null);
                    }
                    setSidebarData((sidebarData) => {
                        const newSidebarData = {
                            topics: sidebarData.topics.map((topic) => {
                                if (topic.id === showModal.topicId) {
                                    //we know that 
                                    return {
                                        ...topic, content: topic.content.filter(contentObj => contentObj.id !== showModal.testId)
                                    }
                                } else {
                                    return topic;
                                }
                            })
                        }
                        return newSidebarData;
                    })
                    setShowModal({ ...showModal, show: false })
                }
            })
        }
        if (showModal.deleteWhat === 'topic') {
            console.log('topic id ', showModal.topicId);
            axios.delete(`${BASEURL}/api/delete-topic`, {
                params: {
                    topicId: showModal.topicId
                }
            }).then((response) => {
                console.log(response, 'delete topic');
                /*
                    case 1 : when user is editing slide group or test when the topic is deleted from the same topic -- here you have to set current data to null for everything

                    case 2: when user is editing some other topics content but deletes the other topic -- here you just have to update the sidebar 

                    case 3: when user delete the topic and there are no other topics -- here you just have to update the sidebar
                
                */
                if (response.data.message === 'successfully delted topic') {
                    if (selectedTopicId === showModal.topicId) {
                        setSidebarData((sidebarData) => {
                            const newSidebarData = { topics: sidebarData.topics.filter((topic) => topic.id !== showModal.topicId) }
                            return newSidebarData;
                        })
                        setShowModal({ ...showModal, show: false })
                        setSelectedChapterId(null);
                        setSelectedTestId(null);
                    }
                    //even if no content is being edited the below condition will trigger
                    if (selectedTopicId !== showModal.topicId) {
                        setSidebarData((sidebarData) => {
                            const newSidebarData = { topics: sidebarData.topics.filter((topic) => topic.id !== showModal.topicId) }
                            return newSidebarData;
                        })
                        setShowModal({ ...showModal, show: false })
                    }
                }
            })
        }
    }

    async function handleCoverImageChange(event) {
        console.log("file uploaded");

        const file = event.target.files[0];

        if (file) {
            try {
                const formData = new FormData();
                formData.append('coverImage', file);
                formData.append('courseId', courseId);

                const response = await axios.post(`${BASEURL}/api/update-course-image`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log(response.data.message);
                const imageUrl = URL.createObjectURL(file);

                setCoverImage(imageUrl);
                toast.success("Course image updated successfully");
            } catch (error) {
                console.error("Error updating course image:", error);
                toast.error("Failed to update course image");
            }
        }
    }

    async function handleCoverImageDelete() {
        try {
            const response = await axios.post(`${BASEURL}/api/delete-course-image`, { courseId });

            //that means image is deleted
            setCoverImage("");
            toast.success("Course image deleted successfully");

        } catch (error) {
            console.error("Error deleting course image:", error);
            toast.error("Failed to delete course image");
        }
    }

    const handleAddNewTopicItemClick = (e, topic) => {
        // e.stopPropagation();
        // const rect = e.target.getBoundingClientRect();
        // const dropdown = document.getElementById(`${topic.id}`);
        // dropdown.classList.remove('invisible');
        // dropdown.style.top = `${rect.bottom}px`; // Position below the trigger
        // dropdown.style.left = `${rect.left + 50}px`; // Align with the trigger

        e.stopPropagation();

        // Get bounding rectangle of the clicked element
        const rect = e.target.getBoundingClientRect();
        const dropdown = document.getElementById(`${topic.id}`);

        // Remove 'invisible' to show the dropdown
        dropdown.classList.remove('invisible');

        // Calculate initial position for the dropdown
        let top = rect.bottom;
        let left = rect.left + 50;

        // Get dropdown dimensions
        const dropdownRect = dropdown.getBoundingClientRect();

        // Check if the dropdown goes beyond the viewport height and adjust top if needed
        if (top + dropdownRect.height > window.innerHeight) {
            top = rect.top - dropdownRect.height; // Place it above the trigger if it's too low
        }

        // Check if the dropdown goes beyond the viewport width and adjust left if needed
        if (left + dropdownRect.width > window.innerWidth) {
            left = window.innerWidth - dropdownRect.width - 10; // Adjust to fit within viewport width
        }

        // Apply calculated top and left values
        dropdown.style.top = `${top}px`;
        dropdown.style.left = `${left}px`;
    }

    return (
        <div id='sidebar' className={`transition-all duration-300 h-full ${sidebarToggle ? 'w-[0px]' : 'w-[300px]'} bg-[#382F8A0D] overflow-auto`}>
             {
                aiToggle && (
                    <div className="fixed inset-0 z-50 flex justify-end">
                        <div className="w-2/6 h-full bg-white shadow-xl p-4 transform transition-transform duration-300 ease-in-out">

                            {/* Add your sidebar content here */}
                            <div className="mt-4">

                                <div className="flex flex-row justify-between">
                                    <h2 className="text-lg font-semibold">AI Content Generator</h2>
                                    <button onClick={() => { setAiToggle(false)}} className="text-gray-700 hover:text-red-500 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>

                                    </button>
                                </div>

                                <div className="mb-2 mt-8">
                                    <label class="text-sm text-gray-700">
                                        Describe your content needs
                                    </label>

                                    <input autoComplete={false} value={contentNeeds} onChange={(e) => { setContentNeeds(e.target.value) }} class="block mt-2 w-full px-2 py-2 text-xs text-gray-700 bg-white border border-gray-200" type="text" name="text" />
                                </div>

                                <div className="mb-2">
                                    <label class="text-sm text-gray-700">
                                        Number of Words
                                    </label>

                                    <input type='number' min='0' value={textLength} onChange={(e) => { setTextLength(e.target.value) }} name="text"
                                        class=" block mt-2 w-full px-2 py-2 text-sm text-gray-700 bg-white border border-gray-200 " />
                                </div>

                                <div className="mb-2">
                                    <label class="text-sm text-gray-700">
                                        Reading Level (Age)
                                    </label>

                                    <input autoComplete="off" value={readingLevel} onChange={(e) => { setReadingLevel(e.target.value) }} type="text" name="text"
                                        class=" block mt-2 w-full px-2 py-2 text-sm text-gray-700 bg-white border border-gray-200  " />
                                </div>
                                {/* 
                                <div className="mt-4 flex items-center justify-end gap-x-2">

                                    <button className="px-4 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40">
                                        Generate
                                    </button>
                                </div> */}

                                <div className="mt-2 flex items-center justify-end gap-x-2">
                                    <button disabled={loading} onClick={handleGenerateContent} className="px-4 py-2.5 text-sm font-medium text-white bg-customPrimaryPurple  transition-colors duration-300 transform ">
                                        {
                                            loading ? (
                                                <>
                                                    <svg aria-hidden="true" role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                                                    </svg>
                                                    Generating
                                                </>
                                            ) : (
                                                <>Generate</>
                                            )
                                        }
                                    </button>
                                </div>

                                <div className="mt-2">
                                    <label class="text-sm text-gray-700 ">
                                        Result
                                    </label>
                                    <textarea value={result} rows={6} type="text" name="text"
                                        className="text-xs block mt-2 w-full px-2 py-2 text-gray-700 bg-white border border-gray-200  " />
                                    <div className="text-right">
                                        {
                                            copied ? (
                                                <button className="px-4 py-2.5 text-sm font-medium text-white bg-customPrimaryPurple  transition-colors duration-300 transform mt-2">Copied</button>
                                            ) : (
                                                <button onClick={(e) => {
                                                    navigator.clipboard.writeText(result).then(function () {
                                                        setCopied(true);
                                                    })
                                                }} className="px-4 py-2.5 text-sm font-medium text-white bg-customPrimaryPurple  transition-colors duration-300 transform  focus:ring-opacity-40 mt-2">Copy</button>
                                            )
                                        }
                                    </div>
                                </div>


                                {/* Add more sidebar elements as needed */}
                            </div>
                        </div>
                    </div>
                )
            }
            <div onClick={() => {
                setAiToggle(true)
            }} className='fixed z-30 w-12 h-12 rounded-full bottom-5 right-5 bg-customPrimaryPurple flex items-center justify-center text-white cursor-pointer'>
                AI
            </div>
            {
                showModal.show &&
                <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                    <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                            <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                                <div className='flex w-full justify-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                    </svg>
                                </div>
                                <div className="flex justify-center py-2 px-3">
                                    <span className='font-bold'>Are you sure?</span>

                                </div>
                                <div className="flex justify-center py-2 px-3">
                                    <span className='text-xs text-gray-600'>Do you really want to delete this?This action cannot be undone.</span>

                                </div>
                                <div className="flex justify-around">
                                    <button className='px-3 py-1 border-2 border-gray-300 rounded-lg text-gray-500 text-xs' onClick={() => { setShowModal({ show: false, topicId: '', subTopicId: '', testId: '', deleteWhat: '' }) }}>Cancel</button>
                                    <button onClick={() => { deleteEntity() }} className='px-3 py-1 bg-slate-500 text-white rounded-lg text-xs'>Yes, Delete it!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='border-b p-4'>
                {
                    baseRoute === 'course-builder' && (
                        <Link to="/myCourses/draftCourses" onClick={handleAllCoursesClick}>
                            <div className='flex gap-2'>
                                <LeftArrow />
                                <div>
                                    Back
                                </div>
                            </div>
                        </Link>
                    )
                }
                {
                    baseRoute === 'admin-course-builder' && (
                        <div className='flex gap-2'>
                            <Link to="/diactoAdmin/courses" onClick={handleAllCoursesClick}>
                                <LeftArrow />
                                <div>
                                    Back
                                </div>
                            </Link>
                        </div>
                    )
                }
            </div>
            <div className='p-3'>
                {
                    coverImage ? (
                        <div className={`relative  w-full h-36 ${coverImage ? '' : 'border-2 border-dashed border-[#BDBDBD]'} bg-white group`}>
                            {coverImage ? (
                                <img
                                    src={coverImage}
                                    alt="cover image"
                                    className="object-cover object-center w-full h-full transition-all duration-300 group-hover:blur-sm group-hover:brightness-75"
                                    onError={(e) => {
                                        console.error(`Error loading image for course ${courseId}:`, e);
                                        e.target.src = 'path/to/fallback/image.jpg';
                                    }}
                                />
                            ) : (
                                <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center group-hover:blur-sm group-hover:brightness-75">
                                    <p>{coverImage ? 'Loading image...' : 'No image available'}</p>
                                </div>
                            )}

                            {/* Hover overlay with buttons */}
                            <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 space-y-4">
                                {/* <button
                                    className="flex items-center justify-center bg-white hover:bg-gray-200 text-xs font-medium p-2 rounded transition duration-300"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                    </svg>
                                    <p>Edit Image</p>
                                </button> */}
                                <button
                                    onClick={handleCoverImageDelete}
                                    className="flex items-center justify-center bg-white hover:bg-gray-200 text-xs font-medium p-2 rounded transition duration-300"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1.5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                    <p>Delete Image</p>
                                </button>
                            </div>
                        </div>

                    ) : (
                        <div className='flex flex-col justify-center items-center gap-2 w-full h-36 bg-white' style={{ border: '1px dashed #BDBDBD', borderRadius: '1px' }}>
                            <div className='bg-[#EDECF9] px-6 py-2 rounded-xl'>
                                <ImageSVG />
                            </div>
                            <div className='text-[#4F4F4F] text-xs'>
                                Add Cover Image
                            </div>
                            <div>
                                <input onChange={handleCoverImageChange} id='cover-image' type='file' accept='image/*' name="cover-image" className='hidden' />
                                <label for='cover-image' className='text-[#382F8A] font-semibold cursor-pointer'>Browse</label>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                sidebarData && <div className='p-3 mt-2'>
                    {
                        sidebarData.topics.map((topic, index) => {
                            return (
                                <div key={index} className='w-full rounded mb-1'>
                                    <div className=''>
                                        <div key={index} className='accordion-header py-1 flex justify-between items-center bg-transparent'
                                            onMouseEnter={(event) => { setIsHovering(`topic${topic.id}`) }}
                                            onMouseLeave={() => { setIsHovering(''); }}
                                            title={`${topic.name}`}
                                        >
                                            <div className='flex items-center w-2/3'>
                                                <button className='toggle mr-1'
                                                    onClick={handleToggleClick}
                                                    ref={buttonRef}
                                                >
                                                    <DoubleRightArrows />
                                                </button>
                                                {
                                                    isEditing === `topic${topic.id}` ? (
                                                        <input onKeyUp={(e) => {
                                                            console.log("enter pressed")
                                                            if (e.key === 'Enter') {
                                                              editTopicName(topic.id);
                                                            }
                                                          }} className='text-base font-medium text-[#282828] outline-none w-full border-b border-customPrimaryPurple' value={newName} onChange={(e) => { setNewName(e.target.value) }} ></input>
                                                    ) : (<span className='text-base truncate w-full text-[#282828] font-medium'>{topic.name}</span>)
                                                }
                                            </div>
                                            <div className={`${isHovering === `topic${topic.id}` ? '' : 'invisible'} flex `}>
                                                {
                                                    isEditing === `topic${topic.id}` ? (
                                                        <i className="fa-regular fa-floppy-disk me-2 text-xs"
                                                            onClick={() => {
                                                                //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                //we need to make an api call to edit-semester-name then in response we need to update the frontend.
                                                                editTopicName(topic.id);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        ></i>

                                                    ) : (
                                                        <i className="fa-solid fa-pen me-2 text-xs"
                                                            onClick={() => {
                                                                //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                setIsEditing(`topic${topic.id}`);
                                                                setNewName(topic.name);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        ></i>

                                                    )
                                                }
                                                <svg onClick={() => {
                                                    setShowModal({ ...showModal, show: true, topicId: topic.id, deleteWhat: 'topic' });
                                                }}
                                                    style={{ cursor: 'pointer', marginRight: "3px" }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 me-2 text-xs">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className='accordion-body max-h-0 overflow-hidden transition-all duration-300 ease-in-out'>
                                            <div className=''>
                                                {
                                                    topic.content.map((contentObject, index) => {
                                                        if (contentObject.type === 'slide-group') {
                                                            return (
                                                                <div key={index} name="single sub topic" className='flex justify-between items-center mb-1 p-1 pl-5'
                                                                    onMouseEnter={() => { setIsHovering(`subTopic${contentObject.id}`); console.log('chapter', contentObject.id) }}
                                                                    onMouseLeave={() => { setIsHovering(null) }}
                                                                    title={`${contentObject.name}`}
                                                                    style={selectedChapterId === contentObject.id ? { backgroundColor: '#DBD9F2', color: '#333' } : {}}
                                                                >
                                                                    <div className='flex gap-2 w-2/3 items-center text-sm'>
                                                                        <PageGroupIcon />
                                                                        {
                                                                            isEditing === `subtopic${contentObject.id}` ?
                                                                                (
                                                                                    <input onKeyUp={(e) => {
                                                                                        console.log("enter pressed")
                                                                                        if (e.key === 'Enter') {
                                                                                            editSubTopicName(topic.id, contentObject.id);
                                                                                        }
                                                                                      }} 
                                                                                      className='outline-none border-b-sky-300 border-b-2 w-full text-xs' value={newName} onChange={(e) => { setNewName(e.target.value) }} ></input>
                                                                                ) : (
                                                                                    <label onClick={() => { handleSubTopicClick(contentObject.id, topic.id); }} className='w-full truncate text-xs'>{contentObject.name}</label>
                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className={`${isHovering === `subTopic${contentObject.id}` ? '' : 'invisible'} flex`}>
                                                                        {
                                                                            isEditing === `subtopic${contentObject.id}` ? (
                                                                                <i className="fa-regular fa-floppy-disk me-2 text-xs cursor-pointer"
                                                                                    onClick={() => {
                                                                                        //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                                        //we need to make an api call to edit-semester-name then in response we need to update the frontend.
                                                                                        editSubTopicName(topic.id, contentObject.id);
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i className="fa-solid fa-pen me-2 text-xs cursor-pointer"
                                                                                    onClick={() => {
                                                                                        //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                                        setIsEditing(`subtopic${contentObject.id}`);
                                                                                        setNewName(contentObject.name);
                                                                                    }}
                                                                                ></i>
                                                                            )
                                                                        }
                                                                        <svg style={{ cursor: 'pointer', marginRight: "3px" }} onClick={() => setShowModal({ ...showModal, show: true, deleteWhat: 'subTopic', subTopicId: contentObject.id, topicId: topic.id })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 me-1">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                        </svg>
                                                                    </div>
                                                                    {
                                                                        selectedChapterId === contentObject.id && (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        if (contentObject.type === 'test') {
                                                            return (
                                                                <div key={index} name="single sub topic" className='flex justify-between items-center p-1 mb-1 pl-5'
                                                                    onMouseEnter={() => { setIsHovering(`test${contentObject.id}`); console.log('test', contentObject.id) }}
                                                                    onMouseLeave={() => { setIsHovering(null) }}
                                                                    title={`${contentObject.name}`}
                                                                    style={selectedTestId === contentObject.id ? { backgroundColor: '#DBD9F2', color: '#333' } : {}}
                                                                >
                                                                    <div className='flex gap-2 w-2/3 items-center text-sm'>
                                                                        <TestIcon />
                                                                        {
                                                                            isEditing === `test${contentObject.id}` ?
                                                                                (
                                                                                    <input onKeyUp={(e) => {
                                                                                        console.log("enter pressed")
                                                                                        if (e.key === 'Enter') {
                                                                                            editTestName(topic.id, contentObject.id);
                                                                                        }
                                                                                      }}  className='outline-none border-b-2 border-b-sky-300 w-full text-xs' value={newName} onChange={(e) => { setNewName(e.target.value) }} ></input>
                                                                                ) : (
                                                                                    <label onClick={() => { console.log('test clicked?'); handleTestClick(contentObject.id, topic.id); }} className='w-full truncate text-xs'>{contentObject.name}</label>

                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className={`${isHovering === `test${contentObject.id}` ? '' : 'invisible'} flex items-center`}>
                                                                        {
                                                                            isEditing === `test${contentObject.id}` ? (
                                                                                <i className="fa-regular fa-floppy-disk me-2 text-xs"
                                                                                    onClick={() => {
                                                                                        //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                                        //we need to make an api call to edit-semester-name then in response we need to update the frontend.
                                                                                        editTestName(topic.id, contentObject.id);

                                                                                    }}
                                                                                    style={{ cursor: "pointer" }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i className="fa-solid fa-pen me-2 text-xs"
                                                                                    onClick={() => {
                                                                                        //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                                        setIsEditing(`test${contentObject.id}`);
                                                                                        setNewName(contentObject.name);
                                                                                    }}
                                                                                    style={{ cursor: "pointer" }}
                                                                                ></i>
                                                                            )
                                                                        }
                                                                        <svg style={{ cursor: 'pointer', marginRight: "3px" }} onClick={() => setShowModal({ ...showModal, show: true, topicId: topic.id, testId: contentObject.id, deleteWhat: 'test' })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 me-1">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                        </svg>

                                                                    </div>
                                                                    {
                                                                        selectedTestId === contentObject.id && (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                            <div onClick={(e) => { handleAddNewTopicItemClick(e, topic) }} className='relative flex gap-2 ml-2 mt-2 px-1 pl-2 items-center w-full cursor-pointer'>
                                                <PlusSVG size='size-6' color="purple" />
                                                <div className='text-xs text-customPrimaryPurple font-semibold'>Add topic item</div>

                                                <div id={`${topic.id}`} className='fixed z-20 flex flex-col gap-2 bg-white p-4 w-fit invisible shadow-2xl addTopicItem'>
                                                    <div className='flex gap-2 p-2 items-center hover:bg-[#DBD9F2]'>

                                                        <PageGroupIcon />

                                                        <div onClick={(e) => {
                                                            e.stopPropagation();
                                                            const dropdown = document.getElementById(`${topic.id}`);
                                                            dropdown.classList.add('invisible')
                                                            if (topic.content.length) {
                                                                addSlideGroup(topic.id, 'slide-group', topic.content[topic.content.length - 1].sequence + 1);
                                                            } else {
                                                                addSlideGroup(topic.id, 'slide-group', 1);
                                                            }
                                                        }} className='flex flex-col '>
                                                            <div className='font-bold text-sm'>
                                                                Add Page Group
                                                            </div>
                                                            <div className='text-xs'>
                                                                Add custom pages
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                        const dropdown = document.getElementById(`${topic.id}`);
                                                        dropdown.classList.add('invisible')
                                                        if (topic.content.length) {
                                                            addTest(topic.id, 'test', topic.content[topic.content.length - 1].sequence + 1);
                                                        } else {
                                                            addTest(topic.id, 'test', 1);
                                                        }
                                                    }} className='flex gap-2 p-2 items-center hover:bg-[#DBD9F2]'>

                                                        <TestIcon />

                                                        <div className='flex flex-col '>
                                                            <div className='font-bold text-sm'>
                                                                Add Test
                                                            </div>
                                                            <div className='text-xs '>
                                                                Add custom test
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div>
                                                <div className='flex justify-between p-1'>
                                                    <span className='flex align-items-center gap-1' style={{ cursor: 'pointer', fontSize: '0.8rem' }} onClick={(event) => {
                                                        console.log('order ', topic.content, topic.content.length - 1);
                                                        if (topic.content.length) {
                                                            addSlideGroup(topic.id, 'slide-group', topic.content[topic.content.length - 1].sequence + 1);
                                                        } else {
                                                            addSlideGroup(topic.id, 'slide-group', 1);
                                                        }
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" width="0.5rem" height='0.5rem' stroke="currentColor" className="size-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
                                                        </svg>
                                                        Slide group
                                                    </span>
                                                    <span className='flex align-items-center gap-1' style={{ cursor: 'pointer', fontSize: '0.8rem' }} onClick={() => {
                                                        if (topic.content.length) {
                                                            addTest(topic.id, 'test', topic.content[topic.content.length - 1].sequence + 1);
                                                        } else {
                                                            addTest(topic.id, 'test', 1);
                                                        }
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" width={"1rem"} height={'1rem'} stroke="currentColor" className="size-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                        </svg>
                                                        Test
                                                    </span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <button className='flex justify-center items-center text-[#382F8A] text-xs w-full 2xl:text-base py-1 px-4' style={{
                        border: "1px solid var(--Primary-Colors-Main, #382F8A)",
                        background: "var(--Color---White, #FFF)",
                        boxShadow: "0px 0px 0px 2px #B8B3E5",
                        cursor: 'pointer'
                    }} onClick={() => { addTopic() }}>
                        <PlusSVG size="size-6" /> Add new topic

                    </button>

                </div>
            }
        </div>
    );
}

export default SideBar;




