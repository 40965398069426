
import React, { useState, useEffect } from 'react';
import './App.css';
import { jwtDecode } from "jwt-decode"; // Fix the import here
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import Login from './Components/Global/userLogin/Login';
import ForgetPassword from './Components/Global/userLogin/ForgetPassword';
import Otp from './Components/Global/userLogin/Otp';
import ResetPassword from './Components/Global/userLogin/ResetPassword';
import Sidebar from './Components/Global/Navigation/Sidebar';
import TeacherHome from './Components/Teacher/TeacherHome';
import MyCourses from './Components/Teacher/MyCourses';
import Store from './Components/Teacher/Store';
import TeacherProfile from './Components/Teacher/TeacherProfile';
import AdminLogin from './Components/Admin/AdminLogin';
import AdminHome from './Components/Admin/AdminHome';
import AdminAllTeachers from './Components/Admin/AdminAllTeachers';
import AdminCreateTeacher from './Components/Admin/AdminCreateTeacher';
import AdminTeacherDetails from './Components/Admin/AdminTeacherDetails';
import AdminEditTeacher from './Components/Admin/AdminEditTeacher';
import AdminAllStudents from './Components/Admin/AdminAllStudents';
import AdminCreateStudent from './Components/Admin/AdminCreateStudent';
import AdminStudentDetails from './Components/Admin/AdminStudentDetails';
import AdminEditStudent from './Components/Admin/AdminEditStudent';
import AdminAllMentors from './Components/Admin/AdminAllMentors';
import AdminMentorDetails from './Components/Admin/AdminMentorDetails';
import DiactoAdminHome from './Components/DiactoAdmin/DiactoAdminHome';
import DiactoSchoolDetails from './Components/DiactoAdmin/DiactoSchoolDetails';
import DiactoCreateSchool from './Components/DiactoAdmin/DiactoCreateSchool';
import DiactoEditSchool from './Components/DiactoAdmin/DiactoEditSchool';
import DiactoVerifyCourses from './Components/DiactoAdmin/DiactoVerifyCourses';
import DiactoAllSchools from './Components/DiactoAdmin/DiactoAllSchools';
import DiactoAllMentors from './Components/DiactoAdmin/DiactoAllMentors';
import DiactoAllSponsors from './Components/DiactoAdmin/DiactoAllSponsors';
import DiactoCreateMentor from './Components/DiactoAdmin/DiactoCreateMentor';
import DiactoMentorDetails from './Components/DiactoAdmin/DiactoMentorDetails';
import DiactoEditMentor from './Components/DiactoAdmin/DiactoEditMentor';
import DiactoMyCourses from './Components/DiactoAdmin/DiactoCourses/DiactoMyCourses'
import CourseBuilder from './Components/Global/CourseCreationHub/CourseBuilder/CourseBuilder'
import Preview from './Components/Global/PreviewComponent/Preview'
import StudentHome from './Components/Student_Desktop/StudentHome'
import StudentMyCourses from './Components/Student_Desktop/StudentMyCourses';
import CourseDetails from './Components/Student_Desktop/CourseDetails';
import DiactoStoreCourses from './Components/DiactoAdmin/DiactoStoreCourses';
import CourseConsumption from './Components/Student_Desktop/CourseConsumption';
import ProtectedRoutes from './ProtectedRoutes';
import CreditPoints from './Components/Student/CreditPoints';
import VerifyEmail from './Components/Global/userLogin/VerifyEmail';
import Layout from './Components/Layout/Layout';

const App = () => {

  const [userRole, setUserRole] = useState(null);

  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("auth");
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // changingRoutes branch

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/adminLogin" element={<AdminLogin />} />
          <Route path="/forgotPassword" element={<ForgetPassword />} />
          <Route path="/verify-otp" element={<Otp />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/resetPassword" element={<ResetPassword />} />

          <Route path="/course-builder/:courseId" element={<ProtectedRoutes><CourseBuilder /></ProtectedRoutes>} />
          <Route path="/admin-course-builder/:courseId" element={<ProtectedRoutes><CourseBuilder /></ProtectedRoutes>} />

          <Route path="/publish-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />
          <Route path="/draft-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />

          <Route path="/quick-preview/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />
          <Route path="/admin-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />
          <Route path="/verify-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />
          <Route path="/store-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />

          <Route path='/CourseConsumption/:courseId/:sharedCourseId' element={<ProtectedRoutes><CourseConsumption /></ProtectedRoutes>} />
 
          <Route path="/" element={<Layout />}>
            <Route index path='home' element={<TeacherHome />} />
            <Route path='myCourses/:activeTab' element={<MyCourses />} />
            <Route path='store' element={<Store />} />
            <Route path='profile' element={<TeacherProfile />} />

            {/* Admin Portal Routes  */}
            <Route path="/admin/home/:userId/:schoolId" element={<AdminHome />} />
            <Route path='student/home' element={<StudentHome />} />

            {/* --------------------------- Teacher Related ---------------------------  */}
            <Route path='/admin/allCreators/:schoolId' element={<AdminAllTeachers />} />
            <Route path='/admin/addCreator/:schoolId' element={<AdminCreateTeacher />} />
            <Route
              path="/admin/allCreators/:schoolId/:userId"
              element={<AdminTeacherDetails />}
            />
            <Route
              path="/admin/editCreatorProfile/:schoolId/:userId"
              element={<AdminEditTeacher />}
            />

            {/* --------------------------- Student Related ---------------------------  */}


            <Route path='/student/home' element={<StudentHome />} />
            <Route path='/student/myCourses' element={<StudentMyCourses />} >

            </Route>
            <Route path='/student/course-points' element={<CreditPoints />} />
            <Route path='/CourseDetails/:courseId/:sharedCourseId' element={<CourseDetails />} />

            <Route
              path="/admin/allStudents/:schoolId"
              element={<AdminAllStudents />}
            />

            <Route
              path="/admin/addStudent/:schoolId"
              element={<AdminCreateStudent />}
            />

            <Route
              path="/admin/allStudents/:schoolId/:userId"
              element={<AdminStudentDetails />}
            />

            <Route
              path="/admin/editStudent/:schoolId/:userId"
              element={<AdminEditStudent />}
            />
            {/* --------------------------- Mentor Related ---------------------------  */}

            <Route
              path="/admin/allMentors/:schoolId"
              element={<AdminAllMentors />}
            />

            <Route
              path="/admin/allMentors/:schoolId/:userId"
              element={<AdminMentorDetails />}
            />

            {/* --------------------------- SUPER ADMIN Routes ---------------------------  */}

            <Route
              path='/diactoAdmin/home'
              element={<DiactoAdminHome />}
            />

            <Route
              path='/diactoAdmin/allSchools'
              element={<DiactoAllSchools />}
            />

            <Route
              path="/diactoAdmin/allSchools/:schoolId"
              element={<DiactoSchoolDetails />}
            />
            <Route
              path="/diactoAdmin/createSchool"
              element={<DiactoCreateSchool />}
            />

            <Route
              path="/diactoAdmin/editSchool/:schoolId"
              element={<DiactoEditSchool />} //pending 
            />

            <Route
              path="/diactoAdmin/allMentors"
              element={<DiactoAllMentors />}
            />

            <Route
              path="/diactoAdmin/allMentors/:userId"
              element={<DiactoMentorDetails />}
            />

            <Route
              path="/diactoAdmin/createMentor"
              element={<DiactoCreateMentor />}
            />

            <Route
              path="/diactoAdmin/editMentor/:userId"
              element={<DiactoEditMentor />}
            />
            <Route
              path="/diactoAdmin/allSponsors"
              element={<DiactoAllSponsors />}
            />
            <Route
              path="/diactoAdmin/courses"
              element={< DiactoMyCourses />}
            />
            <Route
              path="/diactoAdmin/verifyCourses"
              element={<DiactoVerifyCourses />}
            />

            <Route
              path="/diactoAdmin/store"
              element={<DiactoStoreCourses />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );



  // return (
  //   <>
  //     <Toaster position="top-right" reverseOrder={false} />
  //     <Router>
  //       <Routes>
  //         <Route path="/" element={<Login />} />
  //         <Route path="/login" element={<Login />} />
  //         <Route path="/login/adminLogin" element={<AdminLogin />} />
  //         <Route path="/forgotPassword" element={<ForgetPassword />} />
  //         <Route path="/verify-otp" element={<Otp />} />
  //         <Route path="/verify-email" element={<VerifyEmail />} />
  //         <Route path="/resetPassword" element={<ResetPassword />} />

  //         <Route path="/course-builder/:courseId" element={<ProtectedRoutes><CourseBuilder /></ProtectedRoutes>} />
  //         <Route path="/admin-course-builder/:courseId" element={<ProtectedRoutes><CourseBuilder /></ProtectedRoutes>} />

  //         <Route path="/publish-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />
  //         <Route path="/draft-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />

  //         <Route path="/quick-preview/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />
  //         <Route path="/admin-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />
  //         <Route path="/verify-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />
  //         <Route path="/store-view/:courseId" element={<ProtectedRoutes><Preview /></ProtectedRoutes>} />

  //         {/* /CourseDetails/ */}
  //         <Route path='/CourseConsumption/:courseId/:sharedCourseId' element={<ProtectedRoutes><CourseConsumption /></ProtectedRoutes>} />

  //         <Route
  //           path='/*'
  //           element={
  //             <ProtectedRoutes>
  //               <Sidebar userRole={userRole}>
  //                 <Routes>
  //                   {/* --------------------Student Portal Routes --------------------------- */}

  //                   {/*<Route path='/student/preview/:courseId/topic/:topicId/subTopic/:subtopicId' element={<StudentPreview />} /> */}


  //                   {/* --------------------Teacher Portal Routes --------------------------- */}
  //                   <Route path='/home' element={<TeacherHome />} />
  //                   <Route path='/myCourses/:activeTab' element={<MyCourses/>} />

  //                   <Route path='/store' element={<Store />} />
  //                   <Route path='/profile' element={<TeacherProfile />} />

  //                   {/*--------------------------- Admin Portal Routes ---------------------------  */}
  //                   <Route path="/admin/home/:userId/:schoolId" element={<AdminHome />} />

  //                   {/* --------------------------- Teacher Related ---------------------------  */}
  //                   <Route path='/admin/allCreators/:schoolId' element={<AdminAllTeachers />} />
  //                   <Route path='/admin/addCreator/:schoolId' element={<AdminCreateTeacher />} />
  //                   <Route
  //                     path="/admin/allCreators/:schoolId/:userId"
  //                     element={<AdminTeacherDetails />}
  //                   />
  //                   <Route
  //                     path="/admin/editCreatorProfile/:schoolId/:userId"
  //                     element={<AdminEditTeacher />}
  //                   />

  //                   {/* --------------------------- Student Related ---------------------------  */}


  //                   <Route path='/student/home' element={<StudentHome />} />
  //                   <Route path='/student/myCourses' element={<StudentMyCourses />} > 

  //                   </Route>
  //                   <Route path='/student/course-points' element={<CreditPoints />} />
  //                   <Route path='/CourseDetails/:courseId/:sharedCourseId' element={<CourseDetails />} />

  //                   <Route
  //                     path="/admin/allStudents/:schoolId"
  //                     element={<AdminAllStudents />}
  //                   />

  //                   <Route
  //                     path="/admin/addStudent/:schoolId"
  //                     element={<AdminCreateStudent />}
  //                   />

  //                   <Route
  //                     path="/admin/allStudents/:schoolId/:userId"
  //                     element={<AdminStudentDetails />}
  //                   />

  //                   <Route
  //                     path="/admin/editStudent/:schoolId/:userId"
  //                     element={<AdminEditStudent />}
  //                   />
  //                   {/* --------------------------- Mentor Related ---------------------------  */}

  //                   <Route
  //                     path="/admin/allMentors/:schoolId"
  //                     element={<AdminAllMentors />}
  //                   />

  //                   <Route
  //                     path="/admin/allMentors/:schoolId/:userId"
  //                     element={<AdminMentorDetails />}
  //                   />

  //                   {/* --------------------------- SUPER ADMIN Routes ---------------------------  */}

  //                   <Route
  //                     path='/diactoAdmin/home'
  //                     element={<DiactoAdminHome />}
  //                   />

  //                   <Route
  //                     path='/diactoAdmin/allSchools'
  //                     element={<DiactoAllSchools />}
  //                   />

  //                   <Route
  //                     path="/diactoAdmin/allSchools/:schoolId"
  //                     element={<DiactoSchoolDetails />}
  //                   />
  //                   <Route
  //                     path="/diactoAdmin/createSchool"
  //                     element={<DiactoCreateSchool />}
  //                   />

  //                   <Route
  //                     path="/diactoAdmin/editSchool/:schoolId"
  //                     element={<DiactoEditSchool />} //pending 
  //                   />

  //                   <Route
  //                     path="/diactoAdmin/allMentors"
  //                     element={<DiactoAllMentors />}
  //                   />

  //                   <Route
  //                     path="/diactoAdmin/allMentors/:userId"
  //                     element={<DiactoMentorDetails />}
  //                   />

  //                   <Route
  //                     path="/diactoAdmin/createMentor"
  //                     element={<DiactoCreateMentor />}
  //                   />

  //                   <Route
  //                     path="/diactoAdmin/editMentor/:userId"
  //                     element={<DiactoEditMentor />}
  //                   />
  //                   <Route
  //                     path="/diactoAdmin/allSponsors"
  //                     element={<DiactoAllSponsors />}
  //                   />
  //                   <Route
  //                     path="/diactoAdmin/courses"
  //                     element={< DiactoMyCourses />}
  //                   />
  //                   <Route
  //                     path="/diactoAdmin/verifyCourses"
  //                     element={<DiactoVerifyCourses />}
  //                   />

  //                   <Route
  //                     path="/diactoAdmin/store"
  //                     element={<DiactoStoreCourses />}
  //                   />
  //                 </Routes>
  //               </Sidebar>
  //             </ProtectedRoutes>

  //           }
  //         />
  //       </Routes>
  //     </Router>
  //   </>
  // );
};

export default App;

