import { useEffect, useState } from "react";
import { Resizable } from 're-resizable';
import axios from "axios";
import { BASEURL } from '../../../../../constants';
import { CrossSVG, AddImageSVG, PlusSVG } from "../../../../Icons/icons";
const McqComponent = ({ setImagesArray,
    slideId, contentId, data, isSorted, setIsDataSaved, imagesArray, setCurrentSlideData }) => {
    //isSorted is toggled when there is a sort opration, isSorted is required because McqComponent was not setting the data from the parent after the sort happend 
    //console.log("rendering mcq component")
    const [mcq, setMcq] = useState(null);
    console.log("------------------------------------data passed by react ", mcq);
    useEffect(() => {
        console.log('1st useEfect setting mcq data');
        if (data) {
            console.log("data exists ", data);
            setMcq(data);
        } else {
            console.log("data does not exists ", data);
            setMcq({
                question: '',
                options: ['', '', '', ''],
                correctAnswer: { 0: false },
                type: 'single',
                imageData: { image: '', width: '400px', height: '' }
            });
        }
    }, []);

    useEffect(() => {
        console.log("useEffect 3 setting parent data to ", mcq);
        setCurrentSlideData((currentSlide) => {
            const newContent = currentSlide.content.map((contentObj) => {
                if (contentId === contentObj.id) {
                    return {
                        ...contentObj, data: mcq
                    }
                } else {
                    return {
                        ...contentObj
                    }
                }
            })
            return { ...currentSlide, content: newContent }
        })

    }, [mcq])



    function updateQuestion(e) {
        setMcq({ ...mcq, question: e.target.value });
        setIsDataSaved(false);
    }

    function saveCorrectAnswer(e, optionIndex) {
        if (mcq.type === 'single') {
            const newCorrectAnswer = { ...mcq.correctAnswer };//new obj option0 option1
            //console.log("newCorrectAnswer 1", newCorrectAnswer)
            newCorrectAnswer[`${optionIndex}`] = true;
            //console.log("newCorrectAnswer 2", newCorrectAnswer)
            for (let option in newCorrectAnswer) {
                if (option !== `${optionIndex}`) {
                    //console.log("key : ", option, "mathched with ", `option${optionIndex}`);
                    newCorrectAnswer[option] = false; //one word takes 2 hours to debug 
                }
                //console.log("newCorrectAnswer", newCorrectAnswer)
            }
            //console.log("newCorrectAnswer last", newCorrectAnswer)
            setMcq({ ...mcq, correctAnswer: newCorrectAnswer });
        }
        if (mcq.type === "multiple") {
            if (e.target.checked) {
                const newCorrectAnswer = { ...mcq.correctAnswer };
                newCorrectAnswer[`${optionIndex}`] = true;
                setMcq({ ...mcq, correctAnswer: newCorrectAnswer });
            } else {
                const newCorrectAnswer = { ...mcq.correctAnswer };
                newCorrectAnswer[`${optionIndex}`] = false;
                setMcq({ ...mcq, correctAnswer: newCorrectAnswer });
            }
        }
        setIsDataSaved(false);
    }

    function saveOption(e, optionIndex) {
        const newMcq = { ...mcq };
        newMcq.options[optionIndex] = e.target.value;
        const newCorrectAnswer = { ...newMcq.correctAnswer }
        if (!e.target.value) {
            newCorrectAnswer[`${optionIndex}`] = false;
            //console.log(newCorrectAnswer);
        }
        newMcq.correctAnswer = newCorrectAnswer;
        setMcq(newMcq);
        setIsDataSaved(false);
    }

    function addOption() {
        const newMcq = { ...mcq };
        newMcq.options.push('');
        newMcq.correctAnswer[`${newMcq.options.length - 1}`] = false;
        setMcq(newMcq);
        setIsDataSaved(false);
    }

    function deleteOption(optIndex) {
        setMcq({ ...mcq, options: mcq.options.filter((option, index) => index !== optIndex) });
        setIsDataSaved(false);
    }

    function handleSingleOrMultiple(e) {
        const newCorrectAnswer = { ...mcq.correctAnswer };
        for (let option in newCorrectAnswer) {
            newCorrectAnswer[option] = false;
        }
        setMcq({ ...mcq, type: e.target.value, correctAnswer: newCorrectAnswer });
        setIsDataSaved(false);
    }

    function handleImageUpload(event) {
        const image = event.target.files[0];
        const url = URL.createObjectURL(image);
        //console.log("asdfsadfsdf" , url);
        event.target.value = null;
        /*
            image needs to be connected with the slide imageIdArray property - store 
            keep the data in the imagesArray -- on save send the imagesArray 
        */
        setMcq({ ...mcq, imageData: { ...mcq.imageData, image: url } });
        setImagesArray((imagesArray) => {
            let newImagesArray = [...imagesArray];
            newImagesArray.push({ id: contentId, data: image });
            return newImagesArray;
        })
        setCurrentSlideData((currentSlideData) => {
            let newCurrentSlideData = { ...currentSlideData, imageIdArray: [...currentSlideData.imageIdArray, contentId] };
            return newCurrentSlideData;
        })
        setIsDataSaved(false);
    }

    function handleResize(e, d, ref, delta) {
        setMcq({ ...mcq, imageData: { ...mcq.imageData, width: ref.style.width, height: ref.style.height, } });
        setIsDataSaved(false);
    }

    function handleDeleteImage() {
        //make an api call to delete from s3 bucket 
        //on save empty the imagesArray so after that if user deletes we know to make api call
        //and if on delete the image is in array we dont make an api call we just remove from ImagesArray.
        if (!imagesArray.some(obj => obj.id === contentId)) {
            //make an api call to remove the image from s3 bucket and also remove it from mcq
            axios.delete(`${BASEURL}/api/delete-image`, {
                data: {
                    key: contentId
                }
            }).then((response) => {
                if (response.data.message === 'deleted successfully') {
                    setMcq({ ...mcq, imageData: { ...mcq.imageData, image: '', width: '400px', height: '300px' } });
                    setCurrentSlideData((currentSlideData) => {
                        let newCurrentSlideData = { ...currentSlideData };
                        newCurrentSlideData.imageIdArray = newCurrentSlideData.imageIdArray.filter(id => id !== contentId);
                        return newCurrentSlideData;
                    })
                }
            })
        } else {
            setImagesArray((imagesArray) => {
                if (imagesArray.length !== 0) {
                    const newImagesArray = imagesArray.filter((obj) => obj.id !== contentId);
                    return newImagesArray;
                }
            })
            setCurrentSlideData((currentSlideData) => {
                let newCurrentSlideData = { ...currentSlideData };
                newCurrentSlideData.imageIdArray = newCurrentSlideData.imageIdArray.filter(id => id !== contentId);
                return newCurrentSlideData;
            })
            setMcq({ ...mcq, imageData: { ...mcq.imageData, image: '', width: '400px', height: '300px' } });
        }
        setIsDataSaved(false);
    }

    return (
        <>
            {
                mcq &&
                <div className="p-6 bg-[#F1F3F6]">
                    <div>
                        <input checked={mcq.type === 'single'} className="mr-2" id="single" value={'single'} type="radio" name="same" onChange={(e) => {
                            const newCorrectAnswer = { ...mcq.correctAnswer };
                            for (let option in newCorrectAnswer) {
                                newCorrectAnswer[option] = false;
                            }
                            setMcq({ ...mcq, type: e.target.value, correctAnswer: newCorrectAnswer });
                            setIsDataSaved(false);
                        }} />
                        <label className="mr-4" htmlFor="single">Single correct</label>

                        <input checked={mcq.type === 'multiple'} className="mr-2" id="multiple" value={'multiple'} type="radio" name="same" onChange={(e) => {
                            const newCorrectAnswer = { ...mcq.correctAnswer };
                            for (let option in newCorrectAnswer) {
                                newCorrectAnswer[option] = false;
                            }
                            setMcq({ ...mcq, type: e.target.value, correctAnswer: newCorrectAnswer });
                            setIsDataSaved(false);
                        }} />

                        <label htmlFor="multiple">Multiple correct</label>
                    </div>
                    <textarea className="w-full mb-1 border p-2 text-xs outline-none" rows={3} type="text" placeholder="Enter your question" value={mcq.question} onChange={(e) => { updateQuestion(e) }} ></textarea>
                    <div className="flex justify-between items-center mb-2">
                        {
                            mcq.imageData.image ? (
                                <div className="w-4/5 flex gap-2 justify-start p-2">
                                    {/* a bit of styling remaining here */}
                                    <Resizable
                                        size={{
                                            width: mcq.imageData.width,
                                            height: mcq.imageData.height
                                        }}
                                        maxWidth='100%'
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        lockAspectRatio={false}
                                        onResizeStop={(e, d, ref, delta) => handleResize(e, d, ref, delta)}
                                    >
                                        <img src={mcq.imageData.image} style={{ height: "100%", width: "100%" }}></img>
                                    </Resizable>
                                    <i style={{ cursor: 'pointer' }} onClick={handleDeleteImage} className="fa-regular fa-circle-xmark"></i>
                                </div>) : (
                                <div className={`border-2 border-dashed p-2`}>
                                    <label className="flex gap-2 items-center cursor-pointer text-gray-500 text-xs" htmlFor={`mcq-image${contentId}`}> <AddImageSVG /> <span className="font-bold text-xs">Add Image</span></label>
                                    <input type='file' accept='image/*' id={`mcq-image${contentId}`} onChange={(event) => handleImageUpload(event)} style={{ display: "none" }}></input>
                                </div>
                            )
                        }

                        {/* <select className="text-xs" style={{ marginLeft: '22px', marginTop: '10px' }} onChange={handleSingleOrMultiple} value={mcq.type}>
                            <option value={"single"}>Single correct</option>
                            <option value={"multiple"}>Multiple correct</option>
                            <option value={"audio"}>Audio</option>
                        </select> */}
                    </div>
                    {/* {
                        mcq.imageData.image ? (
                            
                        ) : null
                    } */}

                    <div className="text-xs flex flex-col gap-2"
                    // style={{
                    //     display: 'grid',
                    //     gridTemplateColumns: 'auto auto',
                    //     gridTemplateRows: `repeat(${Math.ceil((mcq.options.length) / 2)}, 80px)`,
                    //     gap: '0.5rem'
                    // }}
                    >
                        {
                            mcq.type === "single" ? (
                                mcq.options.map((option, optionIndex) => {
                                    return (
                                        <div key={optionIndex} className="flex gap-2 border border-solid h-10 border-gray-300 items-center px-0 py-0 bg-white"
                                        // style={{ display: 'flex', gap: '5px', border: '1px solid #6b7280', alignItems: 'center', padding: '0rem 0.5rem', borderRadius: '0.5rem' }}
                                        >
                                            <span className="flex items-center py-2 px-3 border-r border-solid border-gray-300 h-full text-sm text-[#4F4F4F]">{`${String.fromCharCode(65 + optionIndex)}`}</span>
                                            <input className="text-xl" type="radio" value={option} checked={mcq.correctAnswer[`${optionIndex}`]} name="radio" onChange={(e) => { saveCorrectAnswer(e, optionIndex) }} />

                                            <input className="grow outline-none text-xs" type="text" placeholder={`Answer ${optionIndex + 1}`} value={option} onChange={(e) => { saveOption(e, optionIndex) }} />
                                            <div className="border-l p-2 h-full flex items-center  justify-center" onClick={() => { deleteOption(optionIndex) }}><CrossSVG /></div>
                                        </div>
                                    )
                                })) : (
                                mcq.options.map((option, optionIndex) => {
                                    return (
                                        <div className="flex gap-2 border border-solid h-10 border-gray-300 items-center px-0 py-0 bg-white" key={optionIndex}>
                                            <span className="flex items-center py-2 px-3 border-r border-solid border-gray-300 h-full text-sm text-[#4F4F4F]">{`${String.fromCharCode(65 + optionIndex)}`}</span>
                                            <input type="checkbox" value={option} checked={mcq.correctAnswer[`${optionIndex}`]} disabled={!option} name="radio" onChange={(e) => { saveCorrectAnswer(e, optionIndex) }} />

                                            <input type="text" className="grow outline-none text-xs" placeholder={`Answer ${optionIndex + 1}`} value={option} onChange={(e) => { saveOption(e, optionIndex) }} />
                                            <div className="border-l p-2 h-full flex items-center justify-center" onClick={() => { deleteOption(optionIndex) }}><CrossSVG /></div>
                                        </div>
                                    )
                                }
                                ))
                        }
                    </div>
                    <div className="flex">
                        <button className="py-4 flex items-center justify-center text-[#1F1A4C] text-sm" onClick={addOption}>
                            <PlusSVG color='purple' />
                            Add option
                        </button>
                    </div>
                </div>

            }
        </>
    );
}

export default McqComponent;