import { useState, useEffect } from "react";
import CourseCard from "../Global/CourseCard/CourseCard";
import { useNavigate } from 'react-router-dom'

const RejectedCourses = ({ searchTerm, courseArray }) => {
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const filtered = courseArray.filter(course =>
            course.course_name && course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchTerm, courseArray]);

    function courseCardClickHandler() {
        navigate('')
    }
    return (
        <>

            <div className="flex flex-wrap gap-4">
                {filteredData.length === 0 ? (
                    <div className="col-span-full w-full my-0 mx-auto">
                        <div className="flex justify-center my-0 mx-auto w-full">
                            <div className="flex flex-col items-center text-center max-w-sm">
                                <p className="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 flex justify-center">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>
                                </p>
                                <p className="text-xl font-bold mt-2 text-gray-700">Oops! No courses found.</p>
                                <p className="mt-2">It looks like there are no courses here. Why not create one and get started?</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    filteredData.map((course, index) => (
                        <CourseCard type='rejected' course={course} courseId={course.id} onClickHandler={courseCardClickHandler} />
                    ))

                )}
            </div>
        </>
    );
}

export default RejectedCourses;