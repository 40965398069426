import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import { Toggle, EyeSVG, SaveSVG, LeftChevron, RightChevron } from "../../../Icons/icons";
import { BASEURL } from "../../../../constants";

const Header = ({setCurrentQuestionId ,setQuestion ,setQuestionsArray , question , currentQuestionId ,setSidebarToggle, questionsArray, baseRoute, isDataSaved, setIsDataSaved, setShowSaveDataModal, showSaveDataModal, courseInfo, selectedChapterId, 
    selectedTestId, handleSaveCourse, slidesArray, setCurrentSlideId, setCurrentSlideData, currentSlideId , autoSaveQuestion }) => {

    const navigate = useNavigate();
    const { courseId } = useParams();
    //slidesArray//setCurrentSlideId//setCurrentSlideData//currentSlideId
    
    function nextSlide() {
        //[{id}{id}{id}]
        const currentSlideIndex = slidesArray.findIndex((slide) => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex + 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex + 1]);
    }

    function previousSlide() {
        const currentSlideIndex = slidesArray.findIndex(slide => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex - 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex - 1]);

    }

    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }

    function disableNext() {
        if (currentSlideId === slidesArray[slidesArray.length - 1].id) return disableStyle;
        return {}
    }

    function disablePrevious() {
        if (currentSlideId === slidesArray[0].id) return disableStyle;
        return {}
    }

    
    function disablePreviousQuestion() {
        if (questionsArray[0].id === currentQuestionId) {
            return disableStyle;
        }
    }

    function disableNextQuestion() {
        if (questionsArray[questionsArray.length - 1].id === currentQuestionId) {
            return disableStyle
        }
    }

    function previousQuestion() {

        autoSaveQuestion().then(() => {
            setQuestionsArray((questionArray) => {
                const newQuestionArray = questionArray.map((q) => {
                    if (q.id === currentQuestionId) {
                        return { ...question }
                    } else {
                        return q;
                    }
                });
                return newQuestionArray;
            })
            const currentQuestionsIndex = questionsArray.findIndex(question => question.id === currentQuestionId);
            const previousQuestion = questionsArray[currentQuestionsIndex - 1];
            setQuestion(previousQuestion);
            setCurrentQuestionId(previousQuestion.id)
        })


        // const formdata = new FormData();

        // formdata.append('question', JSON.stringify(question));
        // axios.post(`${BASEURL}/api/auto-save-question`, formdata, {
        //     headers: {
        //         'Content-Type': 'multipart/from-data'
        //     }
        // }).then((response) => {
        //     if (response.data.status === 'successfully saved') {
        //         //q is saved we can now set the questions array and set the q to the newly created q 
        //         setQuestionsArray((questionArray) => {
        //             const newQuestionArray = questionArray.map((q) => {
        //                 if (q.id === currentQuestionId) {
        //                     return { ...question }
        //                 } else {
        //                     return q;
        //                 }
        //             });
        //             return newQuestionArray;
        //         })
        //         const currentQuestionsIndex = questionsArray.findIndex(question => question.id === currentQuestionId);
        //         const previousQuestion = questionsArray[currentQuestionsIndex - 1];
        //         setQuestion(previousQuestion);
        //         setCurrentQuestionId(previousQuestion.id)
        //     }
        // })
    }

    function nextQuestion() {
        /*
            before doing all this we need to save the previous slide 
            send the save-slide request
        */

            autoSaveQuestion().then(() => {
                setQuestionsArray((questionArray) => {
                    const newQuestionArray = questionArray.map((q) => {
                        if (q.id === currentQuestionId) {
                            return { ...question }
                        } else {
                            return q;
                        }
                    });
                    return newQuestionArray;
                })
                const currentQuestionsIndex = questionsArray.findIndex(question => question.id === currentQuestionId);
                const nextQuestion = questionsArray[currentQuestionsIndex + 1];
                setQuestion(nextQuestion);
                setCurrentQuestionId(nextQuestion.id)
            })
        const formdata = new FormData();

        // formdata.append('question', JSON.stringify(question));
        // axios.post(`${BASEURL}/api/auto-save-question`, formdata, {
        //     headers: {
        //         'Content-Type': 'multipart/from-data'
        //     }
        // }).then((response) => {
        //     if (response.data.status === 'successfully saved') {
        //         //q is saved we can now set the questions array and set the q to the newly created q 
        //         setQuestionsArray((questionArray) => {
        //             const newQuestionArray = questionArray.map((q) => {
        //                 if (q.id === currentQuestionId) {
        //                     return { ...question }
        //                 } else {
        //                     return q;
        //                 }
        //             });
        //             return newQuestionArray;
        //         })
        //         const currentQuestionsIndex = questionsArray.findIndex(question => question.id === currentQuestionId);
        //         const nextQuestion = questionsArray[currentQuestionsIndex + 1];
        //         setQuestion(nextQuestion);
        //         setCurrentQuestionId(nextQuestion.id)
        //     }
        // })
    }
    return (
        <>
            {
                showSaveDataModal && (
                    <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                        <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                        <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                                <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                                    <div className='flex w-full justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                        </svg>
                                    </div>
                                    <div className="flex justify-center py-2 px-3">
                                        <span className='font-bold'>Save changes</span>

                                    </div>
                                    <div className="flex justify-center py-2 px-3">
                                        <span className='text-xs text-gray-600'>Save your latest changes first!</span>

                                    </div>
                                    <div className="flex justify-around">
                                        <button className='px-3 py-1 border-2 border-gray-300 rounded-lg text-gray-500 text-xs' onClick={() => { setShowSaveDataModal(false) }}>Cancel</button>
                                        <button onClick={() => { handleSaveCourse(); setShowSaveDataModal(false) }} className='px-3 py-1 bg-slate-500 text-white rounded-lg text-xs'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                courseInfo && (
                    <div className="flex justify-between items-center px-6 py-2 bg-white">
                        <div className="flex items-center">
                            <div className="cursor-pointer" onClick={() => {
                                setSidebarToggle((sidebarToggle) => {
                                    return !sidebarToggle;
                                })
                            }}>
                                <Toggle />
                            </div>
                            <div className="flex flex-col items-start py-0 px-4">
                                <span className="text-2xl font-bold">{courseInfo.course_name}</span>
                                {/* <span className="text-gray-500 text-xs truncate max-w-96 2xl:text-lg" title={courseInfo.course_desc}>{courseInfo.course_desc}</span> */}
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            {/* <i class="fa-solid fa-palette icon-medium bordered-icon"></i> */}
                            <button onClick={() => {
                                if (isDataSaved) {
                                    navigate(`/quick-preview/${courseId}`)
                                } else {
                                    setShowSaveDataModal(true);
                                }

                            }} class="text-xs 2xl:text-base py-1 px-1 cursor-pointer bg-white border border-solid border-[#E0E0E0] flex items-center gap-2">
                                <EyeSVG />
                            </button>

                            <button class="flex gap-2 items-center justify-center text-xs 2xl:text-base py-1.5 px-2 bg-[#382F8A] text-white cursor-pointer " onClick={() => { handleSaveCourse('normal') }}>
                                <SaveSVG />
                                Save
                            </button>
                            {/* <button class="text-xs py-1.5 px-2 rounded-md cursor-pointer bg-slate-800 text-gray-100 border border-solid border-slate-500 font-bold">Publish</button> */}
                            <div className="shrink-0 h-8 w-[1.7px] bg-[#E0E0E0] rounded"></div>
                            {
                                slidesArray && (
                                    <div className='flex items-center gap-2'>
                                        {/*  */}
                                        <span style={disablePrevious()} onClick={previousSlide} className="flex items-center justify-center cursor-pointer border border-[#E0E0E0] w-[30px] h-[30px]">
                                            <LeftChevron />
                                        </span>
                                        <span style={disableNext()} onClick={nextSlide} className="flex items-center justify-center cursor-pointer border border-[#E0E0E0] w-[30px] h-[30px]">
                                            <RightChevron />
                                        </span>
                                    </div>
                                )
                            }
                            {
                                questionsArray && (
                                    <div className="flex items-center gap-2">
                                        <span style={disablePreviousQuestion()} onClick={previousQuestion} className="flex items-center justify-center cursor-pointer border border-[#E0E0E0] w-[30px] h-[30px]">
                                            <LeftChevron />
                                        </span>
                                        {/* <svg style={disablePreviousQuestion()} onClick={previousQuestion} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg> */}
                                        <span style={disableNextQuestion()} onClick={nextQuestion} className="flex items-center justify-center cursor-pointer border border-[#E0E0E0] w-[30px] h-[30px]">
                                            <RightChevron />
                                        </span>
                                        {/* <svg style={disableNextQuestion()} onClick={nextQuestion} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg> */}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Header;