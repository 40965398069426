import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASEURL } from "../../constants";
import CreateCourseModal from "./CreateCourseModal";

import PublishedCourses from "./PublishedCourses.jsx";
import DraftCourses from "./DraftCourses.jsx";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import InReviewCourses from "./InReviewCourses.jsx";
import RejectedCourses from "./RejectedCourses.jsx";


const MyCourses = () => {
  const [publishedCourses, setPublishedCourses] = useState([]);
  const [draftCourses, setDraftCourses] = useState([])
  const [inReviewCourses, setInReviewCourses] = useState([]);
  const [rejectedCourses, setRejectedCourses] = useState([]);
  const { searchTerm } = useOutletContext();
  const { activeTab } = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllCourses = async () => {
      const userId = localStorage.getItem('auth');

      if (userId) {
        const response = await axios.post(`${BASEURL}/api/fetch-courses`, {
          user_id: userId,
        });

        const publishedCoursesResponse = await axios.post(`${BASEURL}/api/fetch-published-courses`);
        const publishedCourseData = publishedCoursesResponse.data.courseData;

        const publishedCourses = publishedCourseData.filter(course =>
          course.verify_status === 'verified' &&
          course.course_status === 'completed' &&
          course.publish_status === 'published'
        );
        const courseData = response.data.courseData;
        const draftCourses = courseData.filter(course => (course.verify_status === 'not verified' && course.course_status === 'incompleted' && course.publish_status === 'unpublished'));
        const inReviewCourses = courseData.filter(course => (course.verify_status === 'pending' && course.course_status === 'completed' && course.publish_status === 'published'))
        const rejectedCourses = courseData.filter(course => (course.verify_status === 'rejected' && course.course_status === 'completed' && course.publish_status === 'published'))

        // const publishedCourses = courseData.filter(course =>(course.verify_status === 'verified' && course.course_status === 'completed' && course.publish_status === 'published') )
        console.log('in review courses', inReviewCourses);
        setDraftCourses(draftCourses);
        setInReviewCourses(inReviewCourses);
        setPublishedCourses(publishedCourses);
        setRejectedCourses(rejectedCourses);
        console.log("draft courses using new logic", publishedCourses);
      }
    }

    fetchAllCourses();
  }, [])


  return (
    <>
      <div className='mx-4'>
        <div className='pt-2 pb-3 flex justify-between '>
          <div className="flex items-center overflow-x-auto whitespace-nowrap">
            <p className="text-2xl text-slate-900 font-bold cursor-pointer">
              My Courses
            </p>
          </div>
          {/* <div className='flex flex-row justify-between flex-x-2'>
            <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
              <div className="grid place-items-center w-12 text-gray-300">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                className="peer w-full outline-none text-sm text-gray-700 pr-2"
                type="text"
                id="search"
                placeholder="Search Course Name"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button type="button" className="inline-flex truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 " onClick={handleOpenModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              <span className='text-sm'>Create Course</span>
            </button>
          </div> */}
        </div>
        <div class="flex overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap">
          <button
            className={`inline-flex gap-1 items-center h-10 px-4 -mb-px text-sm font-medium text-center ${activeTab === 'publishedCourses' ? 'text-customPrimaryPurple bg-transparent border-b-[3px] font-bold border-customPrimaryPurple' : 'text-gray-700 bg-transparent border-b-2 border-transparent'
              } sm:text-base whitespace-nowrap cursor-pointer focus:outline-none`}
            onClick={() => navigate('/myCourses/publishedCourses')}>
            <div>
              Published Courses
            </div>
            <div className="text-xs px-2 py-1 bg-borderColor rounded-md">
              {
                publishedCourses.length
              }
            </div>
          </button>
          <button
            className={`flex gap-1 items-center h-10 px-4 -mb-px text-sm font-medium text-center ${activeTab === 'draftCourses' ? 'text-customPrimaryPurple bg-transparent border-b-[3px] font-bold border-customPrimaryPurple' : 'text-gray-700 bg-transparent border-b-2 border-transparent'
              } sm:text-base whitespace-nowrap cursor-pointer focus:outline-none`}
            onClick={() => navigate('/myCourses/draftCourses')}>
            <div className="font-inter">
              Drafts Courses
            </div>
            <div className="text-xs px-2 py-1 bg-borderColor rounded-md">
              {
                draftCourses.length
              }
            </div>
          </button>
          <button
            className={`flex gap-1 items-center h-10 px-4 -mb-px text-sm font-medium text-center ${activeTab === 'reviewCourses' ? 'text-customPrimaryPurple bg-transparent border-b-[3px] font-bold border-customPrimaryPurple' : 'text-gray-700 bg-transparent border-b-2 border-transparent'
              } sm:text-base whitespace-nowrap cursor-pointer focus:outline-none`}
            onClick={() => navigate('/myCourses/reviewCourses')}>
            <div>
              In review
            </div>
            <div className="text-xs px-2 py-1 bg-borderColor rounded-md">
              {
                inReviewCourses.length
              }
            </div>
          </button>
          <button
            className={`inline-flex gap-1 items-center h-10 px-4 -mb-px text-sm font-medium text-center ${activeTab === 'rejectedCourses' ? 'text-customPrimaryPurple bg-transparent border-b-[3px] font-bold border-customPrimaryPurple' : 'text-gray-700 bg-transparent border-b-2 border-transparent'
              } sm:text-base whitespace-nowrap cursor-pointer focus:outline-none`}
            onClick={() => navigate('/myCourses/rejectedCourses')}>
            <div>
              Rejected
            </div>
            <div className="text-xs px-2 py-1 bg-borderColor rounded-md">
              {
                rejectedCourses.length
              }
            </div>
          </button>
        </div>
        <div className="py-6 mx-auto">
          {
            activeTab === 'publishedCourses' && (
              <PublishedCourses searchTerm={searchTerm} courseArray={publishedCourses} />
            )
          }
          {
            activeTab === 'draftCourses' && (
              <DraftCourses searchTerm={searchTerm} courseArray={draftCourses} setDraftCourses={setDraftCourses} />
            )
          }
          {
            activeTab === 'reviewCourses' && (
              <InReviewCourses searchTerm={searchTerm} courseArray={inReviewCourses} />
            )
          }
          {
            activeTab === 'rejectedCourses' && (
              <RejectedCourses searchTerm={searchTerm} courseArray={rejectedCourses} />
            )
          }
        </div>
      </div>
    </>
  )
}

export default MyCourses



